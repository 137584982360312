import { ChainIdEnum } from "../enums/chain";

type ChainIdToBlockExplorerURL = {
  [chainId in ChainIdEnum]: string;
};

export const CHAIN_EXPLORER_URLS: ChainIdToBlockExplorerURL = {
  [ChainIdEnum.NONE]: "",
  [ChainIdEnum.ETH_MAINNET]: "https://etherscan.io",
  [ChainIdEnum.SEPOLIA_TESTNET]: "https://sepolia.etherscan.io",
  [ChainIdEnum.LOCAL_TESTNET]: "",
  [ChainIdEnum.OPTIMISM]: "https://optimistic.etherscan.io",
  [ChainIdEnum.OPTIMISM_TESTNET]: "https://sepolia-optimism.etherscan.io",
  [ChainIdEnum.ARBITRUM]: "https://arbiscan.io",
  [ChainIdEnum.ARBITRUM_TESTNET]: "https://sepolia.arbiscan.io",
  [ChainIdEnum.ARBITRUM_LOCAL]: "",
  [ChainIdEnum.LINEA_MAINNET]: "https://lineascan.build",
  [ChainIdEnum.BASE]: "https://basescan.org",
};

const aevoL2ExplorerURL = "https://explorer.aevo.xyz";
const aevoL2TestnetExplorerURL = "https://explorer-testnet.aevo.xyz";

export const L2_CHAIN_EXPLORER_URLS: ChainIdToBlockExplorerURL = {
  [ChainIdEnum.NONE]: "",
  [ChainIdEnum.ETH_MAINNET]: aevoL2ExplorerURL,
  [ChainIdEnum.SEPOLIA_TESTNET]: aevoL2TestnetExplorerURL,
  [ChainIdEnum.LOCAL_TESTNET]: "",

  // Same
  [ChainIdEnum.OPTIMISM]: aevoL2ExplorerURL,
  [ChainIdEnum.OPTIMISM_TESTNET]: aevoL2TestnetExplorerURL,
  [ChainIdEnum.ARBITRUM]: aevoL2ExplorerURL,
  [ChainIdEnum.ARBITRUM_TESTNET]: aevoL2TestnetExplorerURL,
  [ChainIdEnum.ARBITRUM_LOCAL]: "",
  [ChainIdEnum.LINEA_MAINNET]: "",
  [ChainIdEnum.BASE]: aevoL2ExplorerURL,
};
