import { AxiosError } from "axios";
import { useContext } from "react";
import useSWR from "swr";
import { GetReferralStatistics200Response } from "../../../codegen-api";
import { AuthContext } from "../../../contexts/AuthContext";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { authApi } from "../../../services/api/apiFetcher";

export const useReferralStatistics = (startTime?: number) => {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = authApi(apiKey, apiSecret);

  return useSWR<GetReferralStatistics200Response, AxiosError>(
    apiKey ? [APIEndpointEnum.REFERRAL_STATISTICS, apiKey] : undefined,
    async () => (await (await fetcher.getReferralStatistics(startTime))()).data
  );
};
