import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import currency from "currency.js";
import { ethers } from "ethers";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useToast } from "../../hooks/toast";
import useWallet, { ID_TO_CHAINS } from "../../hooks/wallet/useWallet";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import { BaseModal } from "../BaseModal";
import Transition from "../shared/Transition";
import {
  ExtrasContainer,
  ExtrasText,
  PageContentContainer,
  ToastLogoContainer,
  ToastSubheader,
} from "./style";

import { ReactComponent as AevoLogo } from "../../assets/logo/logo_white.svg";
import { CollateralAssetResponse } from "../../codegen-api";
import { TEXT_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { ONCHAIN_ESTIMATES } from "../../constants/onchainEstimates";
import { AccountStateEnum, AuthContext } from "../../contexts/AuthContext";
import { ConnectWalletContext } from "../../contexts/ConnectWalletContext";
import { ChainIdEnum, socketDepositChains } from "../../enums/chain";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { ChainCodeErrorEnum } from "../../enums/rpcErrorCodes";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useClock } from "../../hooks/api/clock/useClock";
import { useRegister } from "../../hooks/api/register/useRegister";
import { useYieldVault } from "../../hooks/api/yieldVault/useYieldVault";
import useDepositERC20OrETH from "../../hooks/contracts/useCollateralAssetOrETH";
import useDeposit, {
  IDepositERC20MultichainResponse,
} from "../../hooks/contracts/useDeposit";
import { useChangeText } from "../../hooks/useChangeText";
import { useSFX } from "../../hooks/useSFX";
import { ISignature } from "../../interfaces/Signing";
import { ModalKeyEnum } from "../../portal";
import { getAssetLogo, getAssetShortName } from "../../utils/asset/assets";
import { CHAIN_EXPLORER_URLS } from "../../utils/chain";
import { isProduction } from "../../utils/env";
import { testnetFaucet } from "../../utils/faucet";
import {
  supportedChainId,
  supportedSigningChainIds,
} from "../../utils/wallet/connectors";
import { ButtonThemeEnum } from "../Buttons/styles";
import EarnModal from "../DepositWithdrawModal/EarnModal";
import Intro from "../DepositWithdrawModal/EarnModal/Intro";
import {
  AssetTitleContainer,
  PausedText,
  TitleTextContainer,
} from "../DepositWithdrawModal/EarnModal/style";
import ExternalLinkText from "../ExternalLinkText";
import GeoblockModal from "../GeoblockModal";
import ManualImportModal from "../ManualImportModal";
import StepButtons from "../StepButtons";
import DepositPendingModal from "../shared/DepositPendingModal";
import EnterPasswordModal from "../shared/EnterPasswordModal";
import ModalOverlayInfo from "../shared/ModalOverlayInfo";
import { OverlayInfoContent } from "../shared/ModalOverlayInfo/style";
import ConnectWallet, { IConnectWalletType } from "./ConnectWallet";
import Deposit from "./Deposit";
import EnableTrading from "./EnableTrading";
import Onboard from "./Onboard";
import SwitchNetwork from "./SwitchNetwork";
import { DepositWithdrawCollaterals, IsUSDC } from "../../constants/addresses";
import { useCheckReferral } from "../../hooks/api/referrals/useCheckReferral";
import OFACModal from "../OFACModal";
import useTerms from "../../hooks/useTerms";
import { PageEndpointEnum } from "../../enums/endpoint";

enum ConnectWalletPageEnum {
  ONBOARD = 0,
  CONNECT_WALLET = 1,
  SWITCH_NETWORK = 3,
  ENABLE_TRADING_SIGN = 4,
  DEPOSIT = 5,
}

// We need this to allow the height to transition seamlessly
type ConnectWalletPageHeightType = {
  [key in ConnectWalletPageEnum]: string;
};
const ConnectWalletPageHeights: ConnectWalletPageHeightType = {
  [ConnectWalletPageEnum.ONBOARD]: "240px",
  [ConnectWalletPageEnum.CONNECT_WALLET]: "240px",
  [ConnectWalletPageEnum.SWITCH_NETWORK]: "320px",
  [ConnectWalletPageEnum.ENABLE_TRADING_SIGN]: "250px",
  [ConnectWalletPageEnum.DEPOSIT]: "300px",
};

const pageWidth = 311;

interface IConnectWalletModalProps {
  show?: boolean;
  onHide?: () => void;
}

function ConnectWalletModal({ show, onHide }: IConnectWalletModalProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "ConnectWalletModal.ConnectWalletModal",
  });

  const referralCode = window.localStorage.getItem(
    LocalStorageKeyEnum.REFERRAL_CODE
  );

  const wallet = useWallet();
  const {
    activate,
    isWrongNetwork,
    setChainToSwitch,
    chainId,
    connectedWallet,
    hasBrowserWallet,
  } = wallet;

  const {
    accountSigningKeyState,
    accountApiKeyState,
    showInvalidRefModal,
    account,
    getImportedAccountAddress,
    reconnectImportedAccount,
    isAccountImported,
    isGeoblocked,
    disconnectAccount,
  } = useContext(AuthContext);
  const location = useLocation();
  const { data: referralData } = useCheckReferral();
  const { getTimestamp } = useClock();
  const { registerSigningKey } = useRegister();
  const { addToast } = useToast();
  const depositForm = useForm({
    mode: "onChange",
  });

  const [showReadyToDepositMessage, setShowReadyToDepositMessage] =
    useState(false);
  const [assetToDeposit, setAssetToDeposit] =
    useState<DepositWithdrawCollaterals>(DepositWithdrawCollaterals.Usdc);
  const [assetAllowance, setAssetAllowance] = useState<string>();

  const { data: yieldVaultData } = useYieldVault();
  const { data: accountData, showOnboarding } = useGetAccount();
  const {
    l1Contract,
    l2Address,
    getAllowance,
    getAssetPermitSignature,
    approveAsset,
    userBalance,
    ethBalance,
    decimals,
  } = useDepositERC20OrETH(wallet, assetToDeposit);
  const {
    l1DepositHelper,
    depositERC20,
    depositERC20WithPermit,
    depositETH,
    depositERC20OptimismOrArbitrum,
    depositETHOptimismOrArbitrum,
    approvalSpender,
    depositFees,
  } = useDeposit(wallet, assetToDeposit);

  // DEPOSIT
  const [selectedDepositChain, setSelectedDepositChain] = useState(
    supportedSigningChainIds[0]
  );

  const onSameNetwork = useMemo(
    () => chainId === selectedDepositChain,
    [chainId, selectedDepositChain]
  );

  const { playSound } = useSFX();
  const depositAmount = useWatch({
    control: depositForm.control,
    name: "amount",
  });

  const [approvedAmountAndSignature, setApprovedAmountAndSignature] = useState<{
    approvedAmount: string | undefined;
    signature: ISignature | undefined;
    deadline: number;
  }>({
    approvedAmount: undefined,
    signature: undefined,
    deadline: 0,
  });
  const [showHowDepositsWork, setShowHowDepositsWork] = useState(false);
  const [pageError, setPageError] = useState("");
  const [depositError, setDepositError] = useState("");

  const {
    yieldVaultToggle,
    setYieldVaultToggle,
    hideOnConnectWallet,
    setHideOnConnectWallet,
  } = useContext(ConnectWalletContext);
  const [showEarnModal, setShowEarnModal] = useState(false);
  const [selectedWallet, setWallet] = useState<IConnectWalletType>();
  const [availablePages, setAvailablePages] = useState<ConnectWalletPageEnum[]>(
    [
      ConnectWalletPageEnum.ONBOARD,
      ConnectWalletPageEnum.CONNECT_WALLET,
      ConnectWalletPageEnum.SWITCH_NETWORK,
      ConnectWalletPageEnum.ENABLE_TRADING_SIGN,
      ConnectWalletPageEnum.DEPOSIT,
    ]
  );

  const [pageIndex, setPageIndex] = useState(0);

  // Update page index based on pageEnum
  const setCurrentPageIndex = useCallback(
    (pageEnum: ConnectWalletPageEnum) => {
      for (let i = 0; i < availablePages.length; i += 1) {
        if (availablePages[i] === pageEnum) {
          setPageIndex(i);
        }
      }
    },
    [availablePages]
  );

  // Update page index based on index
  const updatePageIndex = useCallback(
    (idx: number) => {
      let newIndex = idx;
      if (idx >= availablePages.length) {
        newIndex = availablePages.length - 1;
      } else if (idx < 0) {
        newIndex = 0;
      }
      setPageIndex(newIndex);
    },
    [availablePages.length]
  );

  const pendingDepositTitles = useMemo(
    () => [
      t("initiating_deposit"),
      t("eta", {
        time: `${ONCHAIN_ESTIMATES.deposit.value} ${ONCHAIN_ESTIMATES.deposit.unit}`,
      }),
    ],
    [t]
  );

  const [isLoading, setLoading] = useState(false);
  const [loadingAllowance, setLoadingAllowance] = useState(false);
  const [isConnectingToWallet, setIsConnectingToWallet] =
    useState<IConnectWalletType>();
  const [showImportManuallyModal, setShowImportManuallyModal] = useState(false);

  const [pendingDepositTxURL, setPendingDepositTxURL] = useState<
    string | undefined
  >();
  const pendingDepositTitle = useChangeText(
    pendingDepositTitles,
    5000,
    !!pendingDepositTxURL
  );

  const [showWalletInfoModal, setShowWalletInfoModal] = useState(false);
  const [isRememberMeChecked, setRememberMe] = useState(true);

  const resetStates = useCallback(() => {
    setPageError("");
    setDepositError("");
    setApprovedAmountAndSignature({
      approvedAmount: undefined,
      signature: undefined,
      deadline: 0,
    });
  }, []);

  const { showTerms, setTerms } = useTerms();

  const onHideClicked = useCallback(() => {
    resetStates();
    setYieldVaultToggle(false);
    setShowEarnModal(false);
    onHide?.();
    if (showTerms) {
      disconnectAccount();
    }
  }, [disconnectAccount, onHide, resetStates, setYieldVaultToggle, showTerms]);

  // When network is changed, reset states
  useEffect(() => {
    resetStates();
  }, [selectedDepositChain, resetStates]);

  // When chain is switched, set selected deposit chain
  useEffect(() => {
    if (chainId && supportedSigningChainIds.includes(chainId)) {
      setSelectedDepositChain(chainId);
    }
  }, [chainId]);

  // When asset is changed, get allowance if needed
  useEffect(() => {
    if (approvalSpender && (!getAssetPermitSignature || !assetAllowance)) {
      const loadAssetAllowance = async () => {
        setLoadingAllowance(true);
        try {
          const allowance = await getAllowance(approvalSpender);
          setAssetAllowance(allowance);
        } catch (error) {
          // Nothing
        } finally {
          setLoadingAllowance(false);
        }
      };
      loadAssetAllowance();
    } else {
      setAssetAllowance("0");
    }
  }, [approvalSpender, assetAllowance, getAllowance, getAssetPermitSignature]);

  useEffect(() => {
    if (showInvalidRefModal) {
      onHideClicked();
    }
  }, [onHideClicked, showInvalidRefModal]);

  useEffect(() => {
    // On show, decide which page to show
    // If wallet already connected, onboarding and connect wallet no longer available
    const pages: ConnectWalletPageEnum[] = [];

    if (referralCode || yieldVaultToggle) {
      pages.push(ConnectWalletPageEnum.ONBOARD);
    }

    pages.push(ConnectWalletPageEnum.CONNECT_WALLET);

    if (isWrongNetwork) {
      pages.push(ConnectWalletPageEnum.SWITCH_NETWORK);
    }

    if (
      accountSigningKeyState === AccountStateEnum.REQUIRE_REGISTER_SIGNING ||
      accountApiKeyState === AccountStateEnum.REQUIRE_REGISTER_SIGNING
    ) {
      pages.push(ConnectWalletPageEnum.ENABLE_TRADING_SIGN);
    }

    pages.push(ConnectWalletPageEnum.DEPOSIT);

    if (JSON.stringify(availablePages) !== JSON.stringify(pages)) {
      setAvailablePages(pages);
    }

    setLoading(false);
  }, [
    account,
    accountApiKeyState,
    accountData,
    accountSigningKeyState,
    availablePages,
    isAccountImported,
    isGeoblocked,
    isWrongNetwork,
    referralCode,
    yieldVaultToggle,
  ]);

  useEffect(() => {
    if (!account) {
      updatePageIndex(0);
    } else if (
      hideOnConnectWallet &&
      accountApiKeyState === AccountStateEnum.OK
    ) {
      // if only show this modal if wallet connected, hide it once
      setHideOnConnectWallet(false);
      onHide?.();
    } else if (isWrongNetwork) {
      setCurrentPageIndex(ConnectWalletPageEnum.SWITCH_NETWORK);
    } else if (isGeoblocked && location.pathname === PageEndpointEnum.AEVO) {
      onHide?.();
    } else if (
      accountSigningKeyState === AccountStateEnum.REQUIRE_REGISTER_SIGNING ||
      accountApiKeyState === AccountStateEnum.REQUIRE_REGISTER_SIGNING
    ) {
      setCurrentPageIndex(ConnectWalletPageEnum.ENABLE_TRADING_SIGN);
    } else {
      if (
        showOnboarding !== undefined &&
        !showOnboarding &&
        !yieldVaultToggle &&
        !isGeoblocked
      ) {
        onHide?.();
      }
      setCurrentPageIndex(ConnectWalletPageEnum.DEPOSIT);
    }
  }, [
    account,
    accountData,
    accountSigningKeyState,
    isWrongNetwork,
    onHide,
    updatePageIndex,
    yieldVaultToggle,
    showOnboarding,
    setCurrentPageIndex,
    accountApiKeyState,
    hideOnConnectWallet,
    setHideOnConnectWallet,
    isGeoblocked,
    location.pathname,
  ]);

  // When the page changes, stop loading
  useEffect(() => {
    setLoading(false);
  }, [pageIndex]);

  useEffect(() => {
    if (connectedWallet) setWallet(connectedWallet);
  }, [connectedWallet]);

  const hasValidSignature = useMemo(() => {
    if (
      depositAmount &&
      depositAmount === approvedAmountAndSignature.approvedAmount &&
      approvedAmountAndSignature.signature
    ) {
      return true;
    }
    return false;
  }, [
    approvedAmountAndSignature.approvedAmount,
    approvedAmountAndSignature.signature,
    depositAmount,
  ]);

  const depositReady = useMemo(() => {
    if (chainId !== selectedDepositChain) {
      return false;
    }

    // IF permit is allowed, check for valid signature.
    // ELSE, check for allowance
    if (getAssetPermitSignature) {
      return hasValidSignature;
    }
    return Number(assetAllowance) >= (Number(depositAmount) || 0);
  }, [
    chainId,
    selectedDepositChain,
    getAssetPermitSignature,
    assetAllowance,
    depositAmount,
    hasValidSignature,
  ]);

  const page = useMemo(
    () => availablePages[pageIndex],
    [availablePages, pageIndex]
  );

  const connectWallet = useCallback(async () => {
    if (!account) {
      if (selectedWallet) {
        setIsConnectingToWallet(selectedWallet);

        if (selectedWallet !== "manualImport") {
          setLoading(true);
          await activate(selectedWallet);
          setLoading(false);
        } else if (getImportedAccountAddress()) {
          reconnectImportedAccount();
        } else {
          setShowImportManuallyModal(true);
          return;
        }

        setIsConnectingToWallet(undefined);
      }
    }
    updatePageIndex(pageIndex + 1);
  }, [
    account,
    activate,
    getImportedAccountAddress,
    pageIndex,
    reconnectImportedAccount,
    selectedWallet,
    updatePageIndex,
  ]);

  const register = useCallback(async () => {
    setLoading(true);
    const { status, message } = await registerSigningKey(isRememberMeChecked);
    addToast(
      {
        type: ToastEnum.SIMPLE,
        header: message,
        status,
      },
      4000
    );

    const referral = window.localStorage.getItem(
      LocalStorageKeyEnum.REFERRAL_CODE
    );

    if (status === ToastStatusEnum.ERROR) {
      setPageError(message);
    } else if (status === ToastStatusEnum.SUCCESS && referral && referralData) {
      addToast(
        {
          type: ToastEnum.INFO,
          header: t("trading_fee_discount"),
          status: ToastStatusEnum.SUCCESS,
          icon: (
            <ToastLogoContainer>
              <AevoLogo />
            </ToastLogoContainer>
          ),
          stats: [
            {
              label: (
                <ToastSubheader>
                  {t("trading_fee_discount_desc", {
                    refereeDiscount: (
                      Number(referralData.referee_discount) * 100
                    ).toFixed(0),
                  })}{" "}
                  <span>{referral}</span>.
                </ToastSubheader>
              ),
              value: "",
            },
          ],
        },
        4000
      );
      window.localStorage.removeItem(LocalStorageKeyEnum.REFERRAL_CODE);
    }

    setLoading(false);

    if (account && chainId === ChainIdEnum.SEPOLIA_TESTNET && isProduction()) {
      await testnetFaucet(account);
    }
  }, [
    registerSigningKey,
    isRememberMeChecked,
    addToast,
    referralData,
    account,
    chainId,
    t,
  ]);

  const handleDepositOrApprove = useCallback(
    async (data: { amount: string }) => {
      try {
        // Loading
        setLoading(true);
        setPageError("");
        setDepositError("");

        if (!onSameNetwork) {
          throw Error("Wrong Network");
        }
        //
        /**
         * If deposit is not ready:
         * 1. If can permit, ask for permit signature
         * 2. Else, ask to send asset approval
         */
        if (!depositReady) {
          if (l1DepositHelper && getAssetPermitSignature !== undefined) {
            // 1 hour from now
            const deadline = getTimestamp() + 60 * 60;
            const signature = await getAssetPermitSignature(
              l1DepositHelper.address,
              data.amount,
              deadline
            );

            setApprovedAmountAndSignature({
              signature,
              deadline,
              approvedAmount: data.amount,
            });
          } else if (approvalSpender) {
            await approveAsset(approvalSpender, data.amount);
            setAssetAllowance(data.amount);
          }
          setShowReadyToDepositMessage(true);
          return;
        }

        const insufficientLimit = (
          response: IDepositERC20MultichainResponse
        ) => {
          const chainEnum = chainId as ChainIdEnum;
          const otherNetworks = supportedSigningChainIds
            .filter((c) => c !== chainEnum)
            .map((c) => ID_TO_CHAINS[c]);
          const otherNetworksText =
            otherNetworks.length === 2
              ? otherNetworks.join(` ${t("or")} `)
              : otherNetworks.join(", ");
          const currentNetworkName = ID_TO_CHAINS[chainEnum];

          // No limit
          if (response.insufficientLimit?.isZero()) {
            setDepositError(
              t("no_bridge_capacity", {
                currentChain: currentNetworkName,
                otherChains: otherNetworksText,
              })
            );
          } else {
            const limitStr = ethers.utils.formatUnits(
              response.insufficientLimit ?? "0",
              decimals
            );
            let text = t("insufficient_bridge_capacity", {
              chain: ID_TO_CHAINS[chainEnum],
            });
            text += t("reduce_deposit_size", {
              size: `${currency(limitStr).format({
                symbol: "",
              })} ${assetToDeposit}`,
              otherChains: otherNetworksText,
            });
            setDepositError(text);
          }
        };

        // DEPOSIT
        let depositTx: ethers.ContractTransaction | undefined;

        if (assetToDeposit === CollateralAssetResponse.Weth) {
          // DEPOSIT ETH
          const amtETH = ethers.utils.parseEther(data.amount);
          if (socketDepositChains.includes(chainId as ChainIdEnum)) {
            const response = await depositETHOptimismOrArbitrum(amtETH);
            if (response?.tx) {
              depositTx = response.tx;
            } else if (response?.insufficientLimit) {
              insufficientLimit(response);
            }
          } else {
            depositTx = await depositETH(amtETH);
          }
        } else {
          // DEPOSIT ERC20
          const depositAmtBN = ethers.utils.parseUnits(data.amount, decimals);

          // Decide to deposit permit or depositerc20
          if (
            socketDepositChains.includes(chainId as ChainIdEnum) &&
            l1Contract?.address
          ) {
            // Cross chain deposit
            const response = await depositERC20OptimismOrArbitrum(
              depositAmtBN,
              l1Contract.address,
              assetToDeposit,
              assetToDeposit === DepositWithdrawCollaterals.Usdc ||
                assetToDeposit === DepositWithdrawCollaterals.NativeUsdc
                ? yieldVaultToggle
                : false
            );
            if (response?.tx) {
              depositTx = response.tx;
            } else if (response?.insufficientLimit) {
              const chainEnum = chainId as ChainIdEnum;
              const otherNetworks = supportedSigningChainIds
                .filter((c) => c !== chainEnum)
                .map((c) => ID_TO_CHAINS[c]);
              const otherNetworksText =
                otherNetworks.length === 2
                  ? otherNetworks.join(` ${t("or")} `)
                  : otherNetworks.join(", ");
              const currentNetworkName = ID_TO_CHAINS[chainEnum];

              // No limit
              if (response.insufficientLimit.isZero()) {
                setDepositError(
                  t("no_bridge_capacity", {
                    currentChain: currentNetworkName,
                    otherChains: otherNetworksText,
                  })
                );
              } else {
                const limitStr = ethers.utils.formatUnits(
                  response.insufficientLimit,
                  decimals
                );
                let text = t("insufficient_bridge_capacity", {
                  chain: ID_TO_CHAINS[chainEnum],
                });
                text += t("reduce_deposit_size", {
                  size: `${currency(limitStr).format({
                    symbol: "",
                  })} ${assetToDeposit}`,
                  otherChains: otherNetworksText,
                });
                setDepositError(text);
              }
            }
          } else if (l1Contract?.address && l2Address) {
            if (
              getAssetPermitSignature !== undefined &&
              approvedAmountAndSignature.deadline &&
              approvedAmountAndSignature.signature
            ) {
              // deposit with permit
              depositTx = await depositERC20WithPermit(
                l1Contract.address,
                l2Address,
                depositAmtBN,
                approvedAmountAndSignature.deadline,
                approvedAmountAndSignature.signature,
                IsUSDC(assetToDeposit) ? yieldVaultToggle : false
              );
            } else {
              // deposit erc20
              depositTx = await depositERC20(
                l1Contract.address,
                l2Address,
                depositAmtBN,
                IsUSDC(assetToDeposit) ? yieldVaultToggle : false
              );
            }
          }
        }

        // Only hides if a deposit tx was triggered
        if (depositTx) {
          if (yieldVaultToggle) {
            playSound("yv_deposit", true);
          }
          const explorerUrl = CHAIN_EXPLORER_URLS[chainId as ChainIdEnum];
          setPendingDepositTxURL(`${explorerUrl}/tx/${depositTx.hash}`);
          await depositTx.wait(1);

          // Dismiss modal and show success
          setPendingDepositTxURL(undefined);
          onHideClicked?.();
          addToast(
            {
              type: ToastEnum.SIMPLE,
              header: t("deposit_initiated"),
              subheader: t("deposit_initiated_desc", {
                amount: `${currency(data.amount).format({
                  symbol: "",
                })} ${getAssetShortName(assetToDeposit)}`,
                time: `${ONCHAIN_ESTIMATES.deposit.value} ${ONCHAIN_ESTIMATES.deposit.unit}`,
              }),
              status: ToastStatusEnum.SUCCESS,
            },
            10000
          );
        }
      } catch (error: any) {
        if (error.code === ChainCodeErrorEnum.CANCELLED) {
          setPageError(t("deposit_cancelled"));
        } else {
          setPageError(t("deposit_failed"));
          // eslint-disable-next-line no-console
          console.log({ error });
        }
      } finally {
        setLoading(false);
        setPendingDepositTxURL(undefined);
      }
    },
    [
      onSameNetwork,
      depositReady,
      assetToDeposit,
      l1DepositHelper,
      getAssetPermitSignature,
      approvalSpender,
      getTimestamp,
      approveAsset,
      chainId,
      t,
      decimals,
      depositETHOptimismOrArbitrum,
      depositETH,
      l1Contract,
      l2Address,
      depositERC20OptimismOrArbitrum,
      yieldVaultToggle,
      approvedAmountAndSignature.deadline,
      approvedAmountAndSignature.signature,
      depositERC20WithPermit,
      depositERC20,
      onHideClicked,
      addToast,
      playSound,
    ]
  );

  const onPreviousPage = useCallback(() => {
    setPageError("");
    updatePageIndex(pageIndex - 1);
  }, [pageIndex, updatePageIndex]);

  const onNextPage = useCallback(async () => {
    setPageError("");
    switch (page) {
      case ConnectWalletPageEnum.ONBOARD:
        updatePageIndex(pageIndex + 1);
        if (yieldVaultToggle) {
          window.localStorage.setItem(
            LocalStorageKeyEnum.SHOW_EARN_INTRO,
            "true"
          );
        }
        break;
      case ConnectWalletPageEnum.CONNECT_WALLET:
        await connectWallet();
        break;
      case ConnectWalletPageEnum.SWITCH_NETWORK:
        setLoading(true);
        setChainToSwitch(selectedDepositChain).finally(() => {
          setLoading(false);
        });
        break;
      case ConnectWalletPageEnum.ENABLE_TRADING_SIGN:
        if (
          accountSigningKeyState !==
            AccountStateEnum.REQUIRE_REGISTER_SIGNING &&
          accountApiKeyState !== AccountStateEnum.REQUIRE_REGISTER_SIGNING
        ) {
          updatePageIndex(pageIndex + 1);
        } else {
          register();
        }
        break;
      case ConnectWalletPageEnum.DEPOSIT:
        if (chainId !== selectedDepositChain) {
          setChainToSwitch(selectedDepositChain);
        } else {
          depositForm.handleSubmit(handleDepositOrApprove as any)();
        }
        break;
      default:
        break;
    }
  }, [
    accountApiKeyState,
    accountSigningKeyState,
    chainId,
    connectWallet,
    depositForm,
    handleDepositOrApprove,
    page,
    pageIndex,
    register,
    selectedDepositChain,
    setChainToSwitch,
    updatePageIndex,
    yieldVaultToggle,
  ]);

  const currentPageTitle = useMemo(() => {
    switch (page) {
      case ConnectWalletPageEnum.ONBOARD:
        return yieldVaultToggle ? (
          <AssetTitleContainer>
            <img
              src={getAssetLogo(CollateralAssetResponse.AeUsd)}
              alt={CollateralAssetResponse.AeUsd}
            />
            <TitleTextContainer>
              <span style={{ textTransform: "none" }}>
                {CollateralAssetResponse.AeUsd}
              </span>
              {yieldVaultData?.is_paused && (
                <PausedText>Vault Paused</PausedText>
              )}
            </TitleTextContainer>
          </AssetTitleContainer>
        ) : (
          t("welcome_to_aevo")
        );
      case ConnectWalletPageEnum.CONNECT_WALLET:
        return t("select_your_wallet");
      case ConnectWalletPageEnum.SWITCH_NETWORK:
        return t("switch_network");
      case ConnectWalletPageEnum.ENABLE_TRADING_SIGN:
        return t("complete_sign_in");
      case ConnectWalletPageEnum.DEPOSIT:
        return t("deposit");
      default:
        return "";
    }
  }, [page, t, yieldVaultData?.is_paused, yieldVaultToggle]);

  const pageContent = useMemo(() => {
    switch (page) {
      case ConnectWalletPageEnum.ONBOARD:
        return yieldVaultToggle ? (
          <Intro isOnboard />
        ) : (
          <Onboard
            referralCode={referralCode}
            refereeDiscount={referralData?.referee_discount}
          />
        );
      case ConnectWalletPageEnum.CONNECT_WALLET:
        return (
          <ConnectWallet
            hasBrowserWallet={hasBrowserWallet}
            isConnectingToWallet={isConnectingToWallet}
            selectedWallet={selectedWallet}
            setWallet={setWallet}
            connectedWallet={connectedWallet}
            importedWalletAddress={getImportedAccountAddress()}
          />
        );
      case ConnectWalletPageEnum.SWITCH_NETWORK:
        return (
          <SwitchNetwork
            selectedNetwork={selectedDepositChain}
            onChangeSelectedNetwork={setSelectedDepositChain}
          />
        );
      case ConnectWalletPageEnum.ENABLE_TRADING_SIGN:
        return (
          <EnableTrading
            isRememberMeChecked={isRememberMeChecked}
            setRememberMe={setRememberMe}
          />
        );
      case ConnectWalletPageEnum.DEPOSIT:
        return (
          <Deposit
            selectedDepositNetwork={selectedDepositChain}
            showApprovalSuccessMessage={
              depositReady && showReadyToDepositMessage
            }
            showHelpInfo={showHowDepositsWork}
            onHideHelpInfo={() => setShowHowDepositsWork(false)}
            form={depositForm}
            isDepositing={isLoading}
            onChangeAsset={setAssetToDeposit}
            asset={assetToDeposit}
            customError={depositError}
            balance={
              chainId === selectedDepositChain && userBalance
                ? ethers.utils.formatUnits(userBalance, decimals)
                : undefined
            }
            socketDepositInfo={
              chainId === selectedDepositChain &&
              selectedDepositChain !== supportedChainId
                ? {
                    ethBalance,
                    depositFee: depositFees,
                  }
                : undefined
            }
            onChangeSelectedDepositNetwork={setSelectedDepositChain}
            yieldVaultToggle={yieldVaultToggle}
            setYieldVaultToggle={setYieldVaultToggle}
          />
        );
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    isConnectingToWallet,
    selectedWallet,
    connectedWallet,
    isRememberMeChecked,
    depositReady,
    showHowDepositsWork,
    depositForm,
    isLoading,
    assetToDeposit,
    userBalance,
    decimals,
    depositForm.formState.isValid,
    selectedDepositChain,
    yieldVaultToggle,
  ]);

  const stepInfo = useMemo(() => {
    switch (page) {
      case ConnectWalletPageEnum.ONBOARD:
        return {
          prevHidden: true,
          nextLabel: t("continue"),
          nextDisabled: false,
        };
      case ConnectWalletPageEnum.CONNECT_WALLET:
        return {
          // Hidden if no onboard page
          prevHidden: !availablePages.includes(ConnectWalletPageEnum.ONBOARD),
          nextLabel: isLoading ? t("connecting") : t("continue"),
          nextDisabled: !selectedWallet || isLoading,
          info: {
            onInfoClick: () => {
              setShowWalletInfoModal(true);
            },
          },
        };
      case ConnectWalletPageEnum.SWITCH_NETWORK:
        return {
          nextLabel: t("switch_to_chain", {
            chain: ID_TO_CHAINS[selectedDepositChain],
          }),
          nextDisabled: isLoading,
        };
      case ConnectWalletPageEnum.ENABLE_TRADING_SIGN:
        return {
          prevHidden: true,
          nextLabel: isLoading ? t("signing") : t("sign_message"),
          nextDisabled: isLoading,
          showDisconnect: true,
        };
      case ConnectWalletPageEnum.DEPOSIT: {
        let nextLabel = t("deposit");

        if (!onSameNetwork) {
          nextLabel = t("switch_to_chain", {
            chain: ID_TO_CHAINS[selectedDepositChain],
          });
        } else if (!depositReady) {
          if (getAssetPermitSignature) {
            nextLabel = t("permit", { asset: assetToDeposit });
          } else {
            nextLabel = t("approve", { asset: assetToDeposit });
          }
        }

        if (loadingAllowance) {
          nextLabel = t("loading");
        } else if (isLoading) {
          // eslint-disable-next-line no-nested-ternary
          nextLabel = depositReady
            ? t("depositing")
            : getAssetPermitSignature
            ? t("permitting", { asset: assetToDeposit })
            : t("approving", { asset: assetToDeposit });
        }

        // Check for errors
        const notEnoughEthError =
          chainId === selectedDepositChain &&
          selectedDepositChain !== supportedChainId &&
          ethBalance.lt(depositFees || 0);

        return {
          prevHidden: true,
          nextLabel,
          nextDisabled: loadingAllowance || isLoading || notEnoughEthError,
          showDisconnect: true,
          info: {
            onInfoClick: () => {
              setShowHowDepositsWork(true);
            },
          },
        };
      }
      default:
        return {
          nextLabel: "",
          nextDisabled: true,
        };
    }
  }, [
    page,
    t,
    availablePages,
    isLoading,
    selectedWallet,
    selectedDepositChain,
    onSameNetwork,
    depositReady,
    loadingAllowance,
    chainId,
    ethBalance,
    depositFees,
    getAssetPermitSignature,
    assetToDeposit,
  ]);

  if (pendingDepositTxURL) {
    return (
      <DepositPendingModal
        onHide={onHideClicked}
        show={show}
        title={pendingDepositTitle}
        pendingDepositTxURL={pendingDepositTxURL}
      />
    );
  }

  // If geoblocked and not on aevo page show geoblocked modal
  if (isGeoblocked && location.pathname !== PageEndpointEnum.AEVO) {
    return <GeoblockModal show={show} onHide={onHide} />;
  }

  // Just show enter password modal
  if (accountApiKeyState === AccountStateEnum.REQUIRE_PASSWORD) {
    return <EnterPasswordModal onHide={onHideClicked} show={show} />;
  }

  if (showEarnModal) {
    return <EarnModal show={showEarnModal} onHide={onHideClicked} />;
  }

  return (
    <>
      <ManualImportModal
        show={showImportManuallyModal}
        onHide={() => setShowImportManuallyModal(false)}
        onImportSuccess={onHideClicked}
      />

      <OFACModal
        show={showTerms}
        onHide={onHideClicked}
        onContinue={setTerms}
      />
      <BaseModal
        id={ModalKeyEnum.CONNECT_WALLET}
        noContentPadding
        onHide={onHideClicked}
        show={show}
        title={currentPageTitle}
      >
        <ModalOverlayInfo
          show={showWalletInfoModal}
          onClose={() => setShowWalletInfoModal(false)}
          height="280px"
        >
          <OverlayInfoContent>
            <span>{t("wallets")}</span>
            <span>{t("wallets_overlay_desc")}</span>
            <ExternalLinkText
              href="https://ethereum.org/en/wallets/"
              target="_blank"
              rel="noreferrer"
              color={TEXT_COLORS.two}
              wrapperStyle={{
                marginTop: SPACING.three,
              }}
            >
              {t("learn_more")}
            </ExternalLinkText>
          </OverlayInfoContent>
        </ModalOverlayInfo>

        <PageContentContainer
          style={{
            width: pageWidth,
            height: `calc(${
              ConnectWalletPageHeights[page] + (pageError ? "40px" : "0px")
            })`,
          }}
        >
          <Transition
            key={page}
            style={{
              transitionDuration: "0.3s",
              height: "100%",
            }}
          >
            {pageContent}
            {pageError && (
              <ExtrasContainer>
                <ExtrasText type="error">{pageError}</ExtrasText>
              </ExtrasContainer>
            )}
          </Transition>
        </PageContentContainer>
        <StepButtons
          style={{
            width: pageWidth,
          }}
          info={stepInfo.info}
          rightTitle={stepInfo.nextLabel}
          rightButtonTheme={
            (page === ConnectWalletPageEnum.DEPOSIT ||
              page === ConnectWalletPageEnum.SWITCH_NETWORK) &&
            chainId !== selectedDepositChain
              ? ButtonThemeEnum.HIGHLIGHT
              : undefined
          }
          leftHidden={stepInfo.prevHidden}
          showDisconnect={stepInfo.showDisconnect}
          onClickLeft={
            pageIndex > 0 && !isLoading && availablePages.indexOf(page) !== 0
              ? onPreviousPage
              : undefined
          }
          onClickRight={!stepInfo.nextDisabled ? onNextPage : undefined}
        />
      </BaseModal>
    </>
  );
}

export default memo(ConnectWalletModal);
