import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Bar,
  DatafeedConfiguration,
  LibrarySymbolInfo,
  PeriodParams,
  ResolutionString,
  SubscribeBarsCallback,
} from "../../charting_library/charting_library";

import { TRADINGVIEW_DATAFEED_LAST_TRADED_URL } from "../../constants/api/api";
import { useGetMarkets } from "../../contexts/MarketInstrumentContext/useGetMarkets";
import useTradesWSS from "../../hooks/wss/useTradesWSS";
import { isDevelopment } from "../../utils/env";
import { getContractPriceStep } from "../../utils/instruments";
import { IDatafeedType, ITVOHLCResponse } from "./types";
import { InstrumentType } from "../../codegen-api";

enum SupportedResolutionEnum {
  MINUTE = "1",
  FIVE_MINUTE = "5",
  FIFTEEN_MINUTE = "15",
  THIRTY_MINUTE = "30",
  HOUR = "60",
  FOUR_HOUR = "240",
  DAY = "1D",
  WEEK = "1W",
  MONTH = "1M",
}

const datafeedLog = (...args: any) => {
  if (isDevelopment() || false) {
    console.log(...args);
  }
};
const supportedResolutions = Object.values(SupportedResolutionEnum);

const configurationData: DatafeedConfiguration = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: supportedResolutions as ResolutionString[],
  // supports_group_request: false,
  // supports_search: true,
  supports_marks: false,
  supports_timescale_marks: false,
};

/**
 *
 * @param barTime Millis
 * @param resolution resolution
 * @returns millis of barTime + resolution
 */
function getNextBarTimeMillis(barTime: number, resolution: ResolutionString) {
  const time = moment(barTime);
  switch (resolution as SupportedResolutionEnum) {
    case SupportedResolutionEnum.MINUTE:
      time.add(1, "minute");
      break;
    case SupportedResolutionEnum.FIVE_MINUTE:
      time.add(5, "minute");
      break;
    case SupportedResolutionEnum.FIFTEEN_MINUTE:
      time.add(15, "minute");
      break;
    case SupportedResolutionEnum.THIRTY_MINUTE:
      time.add(30, "minute");
      break;
    case SupportedResolutionEnum.HOUR:
      time.add(1, "hour");
      break;
    case SupportedResolutionEnum.FOUR_HOUR:
      time.add(4, "hour");
      break;
    case SupportedResolutionEnum.DAY:
      time.add(1, "day");
      break;
    case SupportedResolutionEnum.WEEK:
      time.add(1, "week");
      break;
    case SupportedResolutionEnum.MONTH:
      time.add(1, "month");
      break;
  }

  return time.valueOf();
}

function maxTimeForResolution(resolution: ResolutionString) {
  switch (resolution as SupportedResolutionEnum) {
    case SupportedResolutionEnum.MINUTE:
      return moment.duration(7, "days").asSeconds();
    case SupportedResolutionEnum.FIVE_MINUTE:
      return moment.duration(15, "days").asSeconds();
    case SupportedResolutionEnum.FIFTEEN_MINUTE:
      return moment.duration(34, "days").asSeconds(); // cache lasts for ~ 35 days
    default:
      return undefined;
  }
}

const callDataOnce = (resolution: ResolutionString) =>
  resolution === SupportedResolutionEnum.MINUTE ||
  resolution === SupportedResolutionEnum.FIVE_MINUTE ||
  resolution === SupportedResolutionEnum.FIFTEEN_MINUTE;

interface IHandler {
  id: string;
  callback: SubscribeBarsCallback;
}

interface ISubscriptionItem {
  subscribeUID: string;
  resolution: ResolutionString;
  lastBar: Bar;
  handlers: IHandler[];
}

/**
 * @param useLastTradedPrice bool. If true, uses last traded prices
 */
export const useDatafeed = () => {
  const { activeMarkets } = useGetMarkets(undefined, InstrumentType.Perpetual);

  // Cache all bars in local variable
  const lastBarsCache = useRef(new Map<string, Bar>());
  const channelToSubscription = useRef(new Map<string, ISubscriptionItem>());
  const [instrument, setInstrument] = useState<string>();

  const { data: trades } = useTradesWSS(instrument);

  useEffect(() => {
    if (instrument) {
      const subscriptionItem = channelToSubscription.current.get(instrument);
      if (!subscriptionItem) {
        return;
      }

      const { lastBar, resolution } = subscriptionItem;
      const nextBarTimeMillis = getNextBarTimeMillis(lastBar.time, resolution);
      let latestPrice = 0;
      let latestVol = 0;

      // If using last traded price, update chart with the latest trade price
      if (!trades?.[0]) {
        return;
      }
      latestPrice = Number(trades[0].price);
      latestVol = Number(trades[0].amount);

      let bar: Bar;
      if (Date.now() >= nextBarTimeMillis) {
        bar = {
          time: nextBarTimeMillis,
          open: latestPrice,
          high: latestPrice,
          low: latestPrice,
          volume: latestVol,
          close: latestPrice,
        };
        datafeedLog("[socket] Generate new bar", bar);
      } else {
        bar = {
          ...lastBar,
          high: Math.max(lastBar?.high || 0, latestPrice),
          low: Math.min(lastBar?.low || 0, latestPrice),
          volume: (lastBar?.volume || 0) + latestVol,
          close: latestPrice,
        };
        datafeedLog("[socket] Update the latest bar by price", latestPrice);
      }

      subscriptionItem.lastBar = bar;

      // send data to every subscriber of that symbol
      subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
    }
  }, [instrument, trades]);

  const url = TRADINGVIEW_DATAFEED_LAST_TRADED_URL;

  const getSymbol = useCallback(
    (symbol: string) => {
      const m = activeMarkets?.find((a) => a.instrument_name === symbol);
      const { amount_precision, price_precision } = getContractPriceStep(m);

      const symbolResponse = {
        exchange: "Aevo",
        listed_exchange: "Aevo",
        data_status: "streaming",
        full_name: symbol,
        name: symbol,
        symbol,
        description: symbol,
        minmovement: 1,
        supported_resolutions: supportedResolutions as ResolutionString[],
        format: "price",
        has_dwm: true,
        has_intraday: true,
        intraday_multipliers: supportedResolutions as ResolutionString[],
        visible_plots_set: "ohlcv",
        type: "perp",
        timezone: "Etc/UTC",
        session: "24x7",
        volume_precision: amount_precision,
        pricescale: 10 ** price_precision,
      } as any;
      return symbolResponse;
    },
    [activeMarkets]
  );

  const getOHLC = useCallback(
    async (
      symbolInfo: LibrarySymbolInfo,
      resolution: ResolutionString,
      periodParams: PeriodParams
    ) => {
      const { data } = await axios.get(`${url}/history`, {
        params: {
          symbol: symbolInfo.name,
          resolution,
          ...periodParams,
        },
      });
      return data as ITVOHLCResponse;
    },
    [url]
  );

  const datafeed: IDatafeedType = useMemo(
    () => ({
      onReady: (callback) => {
        datafeedLog("[onReady]: Method call");
        setTimeout(() => callback(configurationData));
      },
      searchSymbols: (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
      ) => {
        datafeedLog("[searchSymbols]: Method call");
      },
      resolveSymbol: (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
      ) => {
        datafeedLog("[resolveSymbol]: Method call", symbolName);
        const info = getSymbol(symbolName);
        setInstrument(symbolName);
        setTimeout(() => {
          onSymbolResolvedCallback(info as any);
        }, 0);
      },
      getBars: (
        symbolInfo,
        resolution,
        periodParams,
        onHistoryCallback,
        onErrorCallback
      ) => {
        datafeedLog("[getBars]: Method call", symbolInfo);

        const maxDuration = maxTimeForResolution(resolution);

        const { firstDataRequest, to, countBack } = periodParams;

        // call data only once for resolutions 1/5/15min
        // because api calls where from is less than 35 days from now
        // are cached making api call fast
        if (callDataOnce(resolution) && !firstDataRequest) {
          onHistoryCallback([], {
            noData: true,
          });
          return;
        }

        // update period `from` param so that api call will get all data required
        if (maxDuration) {
          // eslint-disable-next-line no-param-reassign
          periodParams = {
            firstDataRequest,
            from: to - maxDuration,
            to,
            countBack,
          };
        }

        getOHLC(symbolInfo, resolution, periodParams)
          .then((v) => {
            if (v.s === "ok") {
              const bars: Bar[] = [];
              v.t.forEach((time, idx) => {
                if (time >= periodParams.from && time < periodParams.to) {
                  bars.push({
                    time: v.t[idx] * 1000,
                    low: v.l[idx],
                    high: v.h[idx],
                    open: v.o[idx],
                    close: v.c[idx],
                    volume: v.v?.[idx] || 0,
                  });
                }
              });

              if (firstDataRequest) {
                lastBarsCache.current.set(symbolInfo.full_name, {
                  ...bars[bars.length - 1],
                });
              }

              onHistoryCallback(bars, {
                noData: false,
              });
            } else if (v.s === "no_data") {
              onHistoryCallback([], {
                noData: true,
              });
            } else if (v.s === "error") {
              onErrorCallback(v.s);
            }
          })
          .catch((e) => onErrorCallback(e));
      },
      subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback
      ) => {
        const handler: IHandler = {
          id: subscribeUID,
          callback: onRealtimeCallback,
        };

        const lastBar = lastBarsCache.current.get(symbolInfo.full_name);
        if (!lastBar) {
          return;
        }

        const subscriptionItem: ISubscriptionItem = {
          subscribeUID,
          resolution,
          lastBar,
          handlers: [handler],
        };
        channelToSubscription.current.set(
          symbolInfo.full_name,
          subscriptionItem
        );
      },
      unsubscribeBars: (subscriberUID) => {
        datafeedLog(
          "[unsubscribeBars]: Method call with subscriberUID:",
          subscriberUID
        );

        // find a subscription with id === subscriberUID
        const channels = Array.from(channelToSubscription.current.keys());
        for (let i = 0; i < channels.length; i += 1) {
          const channel = channels[i];
          const subscriptionItem = channelToSubscription.current.get(channel);
          const handlerIndex =
            subscriptionItem?.handlers.findIndex(
              (handler) => handler.id === subscriberUID
            ) || -1;

          if (handlerIndex !== -1) {
            // remove from handlers
            subscriptionItem?.handlers.splice(handlerIndex, 1);
            if (subscriptionItem?.handlers.length === 0) {
              // unsubscribe from the channel, if it was the last handler
              datafeedLog(
                "[unsubscribeBars]: Unsubscribe from streaming. Channel:",
                channel
              );
              channelToSubscription.current.delete(channel);
              break;
            }
          }
        }
      },
    }),
    [getOHLC, getSymbol]
  );

  return {
    datafeed,
  };
};
