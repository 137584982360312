import { forwardRef, useMemo, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import {
  Explanation,
  ExplanationWrapper,
  PopoverContainer,
  StakingBadgeTextWrapper,
  TooltipTitle,
} from "./style";
import { BadgeTier } from "../../../hooks/api/rewardsData/useRewardsData";
import { TooltipWrapper } from "../Header/AccountModal/style";
import ExternalLinkText from "../../ExternalLinkText";
import { TEXT_COLORS } from "../../../constants/design/colors";

interface IStakingBadgeTextProps {
  stakingBadge?: BadgeTier;
}

const StakingBadgeText = forwardRef<HTMLDivElement, IStakingBadgeTextProps>(
  ({ stakingBadge }, ref) => {
    const { t } = useTranslation("app", {
      keyPrefix: "StakingBadgeText",
    });
    const [show, setShow] = useState(false);
    const fill = useMemo(() => {
      switch (stakingBadge) {
        case "Bronze":
          return "#FF3D00";
        case "Silver":
          return "#B0B0B0";
        case "Gold":
          return "#FB9700";
        case "Platinum":
          return "#E5E4E2";
        case "Diamond":
          return "#1E90FF";
        default:
          return "#FF3D00";
      }
    }, [stakingBadge]);

    const stakingPerks = useMemo(() => {
      switch (stakingBadge) {
        case "Bronze":
          return (
            <ul>
              <li>{t("bronze_perk")}</li>
            </ul>
          );
        case "Silver":
          return (
            <ul>
              <li>{t("bronze_perk")}</li>
              <li>{t("silver_perk")}</li>
            </ul>
          );
        case "Gold":
          return (
            <ul>
              <li>{t("bronze_perk")}</li>
              <li>{t("gold_perk_1")}</li>
            </ul>
          );
        case "Platinum":
          return (
            <ul>
              <li>{t("bronze_perk")}</li>
              <li>{t("platinum_perk_1")}</li>
              <li>{t("platinum_perk_2")}</li>
            </ul>
          );
        case "Diamond":
          return (
            <ul>
              <li>{t("bronze_perk")}</li>
              <li>{t("diamond_perk_1")}</li>
              <li>{t("diamond_perk_2")}</li>
              <li>{t("diamond_perk_3")}</li>
              <li>{t("diamond_perk_4")}</li>
              <li>{t("diamond_perk_5")}</li>
              <li>{t("diamond_perk_6")}</li>
            </ul>
          );
        default:
          return null;
      }
    }, [stakingBadge, t]);

    const explanation = useMemo(
      () => (
        <ExplanationWrapper>
          <div>
            {stakingBadge &&
              t("description", {
                stakingBadge: t(stakingBadge),
              })}
          </div>
          <div>{stakingPerks}</div>
          <ExternalLinkText
            href={
              "https://docs.aevo.xyz/trading-and-staking-incentives/staking-perks"
            }
            target="_blank"
            rel="noreferrer"
            color={TEXT_COLORS.two}
            wrapperStyle={{
              display: "flex",
              justifyContent: "flex-start",
              fontSize: 12,
            }}
          >
            {t("learn_more")}
          </ExternalLinkText>
        </ExplanationWrapper>
      ),
      [stakingBadge, stakingPerks, t]
    );

    if (!stakingBadge) {
      return null;
    }

    return (
      <StakingBadgeTextWrapper
        onClick={(e) => e.stopPropagation()}
        color={fill}
      >
        <div>{t(stakingBadge)}</div>
        <div
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <OverlayTrigger
            placement="bottom"
            show={show}
            overlay={(props) => (
              <PopoverContainer {...props}>
                <div>
                  <TooltipTitle>{t(stakingBadge)}</TooltipTitle>
                  <Explanation ref={ref}>{explanation}</Explanation>
                </div>
              </PopoverContainer>
            )}
            popperConfig={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 0], // small difference to account for small gap
                  },
                },
              ],
            }}
          >
            <TooltipWrapper stroke={fill}>
              <Info />
            </TooltipWrapper>
          </OverlayTrigger>
        </div>
      </StakingBadgeTextWrapper>
    );
  }
);

export default StakingBadgeText;
