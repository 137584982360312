import { ReferralsTab } from "../../ReferralsTab";
import { PageHeader, PageWrapper } from "../style";

function MobileReferralsPage() {
  return (
    <PageWrapper isMobileScreen>
      <PageHeader isMobileScreen>
        <ReferralsTab isMobileScreen />
      </PageHeader>
    </PageWrapper>
  );
}

export default MobileReferralsPage;
