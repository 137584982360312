import { initializeConnector } from "@web3-react/core";
import { Network } from "@web3-react/network";

import { CHAINS } from "../chains";

const allRpcUrls = Object.keys(CHAINS).reduce(
  (prev, key) => ({
    ...prev,
    [key]: CHAINS[Number(key) as keyof typeof CHAINS]?.urls || "",
  }),
  {}
);

export const [network, hooks] = initializeConnector<Network>(
  (actions) => new Network({ actions, urlMap: allRpcUrls })
);
