import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { initializeConnector } from "@web3-react/core";

const rpcURL = `https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_RPC_KEY}`;

export const [coinbaseWallet, hooks] = initializeConnector<CoinbaseWallet>(
  (actions) => new CoinbaseWallet({
    actions,
    options: {
      url: rpcURL || "",
      appName: "Aevo",
    },
  })
);
