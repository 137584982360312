import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { Web3ReactHooks } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import type { Network } from "@web3-react/network";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import {
  AllAltChainIdEnum,
  AllChainIdEnum,
  ChainIdEnum,
} from "../../../enums/chain";
import {
  isDevelopment,
  isProduction,
  isProductionTest,
  isStaging,
} from "../../env";
import { WalletEnum } from "../types";
import { coinbaseWallet, hooks as coinbaseWalletHooks } from "./coinbaseWallet";
import { metaMask, hooks as metaMaskHooks } from "./metamask";
import { network, hooks as networkHooks } from "./network";
import {
  walletConnectV2,
  hooks as walletConnectV2Hooks,
} from "./walletConnectV2";
import { AssetResponse } from "../../asset";

export const supportedChainIds = [
  ...AllChainIdEnum.filter(
    (v) => v !== ChainIdEnum.NONE && v !== ChainIdEnum.LINEA_MAINNET
  ),
  ...AllAltChainIdEnum,
];

const getSupportedChain = () => {
  if (isStaging()) {
    return ChainIdEnum.SEPOLIA_TESTNET;
  }

  if (isDevelopment()) {
    return ChainIdEnum.LOCAL_TESTNET;
  }

  return ChainIdEnum.ETH_MAINNET;
};

const getSupportedDepositChains = () => {
  const chains = [getSupportedChain()];
  if (isStaging()) {
    chains.push(ChainIdEnum.OPTIMISM_TESTNET, ChainIdEnum.ARBITRUM_TESTNET);
  } else if (isProduction() || isProductionTest()) {
    chains.push(ChainIdEnum.OPTIMISM, ChainIdEnum.ARBITRUM, ChainIdEnum.BASE);
  } else {
    chains.push(ChainIdEnum.ARBITRUM_LOCAL);
  }
  return chains;
};

export const supportedChainId: ChainIdEnum = getSupportedChain();

export const lineaChainId: ChainIdEnum = ChainIdEnum.LINEA_MAINNET;

export const airdropChainId = (asset: AssetResponse) => {
  switch (asset) {
    case "ZERO":
      return lineaChainId;
    default:
      return supportedChainId;
  }
};

export const supportedSigningChainIds: ChainIdEnum[] =
  getSupportedDepositChains();

export const allConnectors: [
  MetaMask | WalletConnectV2 | CoinbaseWallet | Network,
  Web3ReactHooks
][] = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks],
  [network, networkHooks],
];

export const walletToConnector: Record<
  WalletEnum,
  () => WalletConnectV2 | MetaMask | CoinbaseWallet | undefined
> = {
  [WalletEnum.METAMASK]: () => metaMask,
  [WalletEnum.WALLETCONNECT]: () => walletConnectV2,
  [WalletEnum.WALLETLINK]: () => coinbaseWallet,
  [WalletEnum.BRAVE]: () => metaMask,
};
