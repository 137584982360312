/* eslint-disable no-nested-ternary */
import {
  CSSProperties,
  MouseEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { InstrumentTypeResponse, TradeTypeResponse } from "../../codegen-api";
import { TEXT_COLORS } from "../../constants/design/colors";
import { AccountStateEnum, AuthContext } from "../../contexts/AuthContext";
import { ConnectWalletContext } from "../../contexts/ConnectWalletContext";
import { MarketContext } from "../../contexts/MarketContext";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useOrder } from "../../hooks/api/order/useOrder";
import { useTradeHistory } from "../../hooks/api/tradeHistory/useTradeHistory";
import {
  IMultiMarkPriceAssetDerivative,
  useMarkPricesWSS,
} from "../../hooks/wss/useMarkPriceWSS";
import usePositionsWSS from "../../hooks/wss/usePositionsWSS";
import { TimePeriodEnum } from "../../interfaces/TimePeriod";
import { getStartTimeSeconds, secondsToNanos } from "../../utils/date";
import { getAssetFromSymbol } from "../../utils/instruments";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import Orders from "../Orders";
import Positions from "../Positions";
import TradeHistory from "../TradeHistory";
import BottomBar from "../shared/BottomBar";
import { Chevron } from "../shared/Chevron/style";
import Toggle from "../shared/Toggle";
import { EmptyContent, SelectedMarketToggleContainer } from "./style";
import { SPACING } from "../../constants/design/spacing";
import { PageEndpointEnum } from "../../enums/endpoint";

export interface ITradingBottomBarProps {
  style?: CSSProperties;
  showOnlyCurrentMarket: boolean;
  setShowOnlyCurrentMarket: (show: boolean) => void;
  onToggleCollapse?: () => void;
  collapsed?: boolean;
}

export enum MarketPortfolioOptionEnum {
  "Positions" = "Positions",
  "OpenOrders" = "Orders",
  // "Funding" = "Funding",
  "History" = "History",
}

export type MarketPortfolioOptionCount = {
  [key in MarketPortfolioOptionEnum]: number | undefined;
};

function TradingBottomBar({
  style,
  onToggleCollapse,
  showOnlyCurrentMarket,
  setShowOnlyCurrentMarket,
  collapsed,
}: ITradingBottomBarProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradingBottomBar.TradingBottomBar",
  });
  const { accountApiKeyState } = useContext(AuthContext);
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const { market } = useContext(MarketContext);
  const navigate = useNavigate();

  const { positions } = usePositionsWSS();

  const uniqueTickers: IMultiMarkPriceAssetDerivative = useMemo(() => {
    const tickers: IMultiMarkPriceAssetDerivative = {};
    positions.forEach((pos) => {
      // If option, check if already subbed
      if (pos.option) {
        const optionTickerExists = Boolean(tickers[pos.instrument_name]);
        if (!optionTickerExists) {
          tickers[pos.instrument_name] = {
            asset: pos.asset,
            derivative: pos.instrument_type,
          };
        }
      } else {
        tickers[pos.instrument_name] = {
          asset: pos.asset,
          derivative: pos.instrument_type,
        };
      }
    });
    return tickers;
  }, [positions]);

  const { instrumentMark } = useMarkPricesWSS(uniqueTickers, 1000);

  const {
    data: accountData,
    isLoading: accountDataLoading,
    showOnboarding,
  } = useGetAccount();

  const { data: oData, isLoading: ordersLoading } = useOrder();

  const oneWeekAgoNanos = useMemo(
    () => secondsToNanos(getStartTimeSeconds(TimePeriodEnum.WEEKLY)),
    []
  );

  const { data: tradeHistoryData, isLoading: tradesLoading } = useTradeHistory(
    oneWeekAgoNanos,
    showOnlyCurrentMarket ? market.asset : undefined,
    [TradeTypeResponse.Trade],
    undefined,
    undefined,
    20,
    undefined,
    showOnlyCurrentMarket ? market.derivative : undefined
  );

  const [currentTab, setCurrentTab] = useState<MarketPortfolioOptionEnum>(
    MarketPortfolioOptionEnum.Positions
  );

  const accountPositions = useMemo(
    () =>
      showOnlyCurrentMarket
        ? positions?.filter((v) => {
            const matchAsset = v.asset === market.asset;
            const matchDerivative =
              market.derivative === InstrumentTypeResponse.Perpetual
                ? !v.option
                : !!v.option;
            return matchAsset && matchDerivative;
          })
        : positions,
    [market, positions, showOnlyCurrentMarket]
  );

  const ordersData = showOnlyCurrentMarket
    ? oData?.filter((v) => {
        const matchAsset =
          getAssetFromSymbol(v.instrument_name) === market.asset;
        const matchDerivative =
          market.derivative === InstrumentTypeResponse.Perpetual
            ? !v.option_type
            : !!v.option_type;
        return matchAsset && matchDerivative;
      })
    : oData;

  const marketPortfolioOptionsCount: MarketPortfolioOptionCount = useMemo(
    () => ({
      Positions: accountPositions?.length ?? 0,
      Orders: ordersData?.length ?? 0,
      // Funding: fundingHistoryData?.length ?? 0,
      History: tradeHistoryData?.trade_history?.length ?? 0,
    }),
    [
      accountPositions?.length,
      ordersData?.length,
      tradeHistoryData?.trade_history?.length,
    ]
  );

  const onHeaderClick: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.stopPropagation();
      onToggleCollapse?.();
    },
    [onToggleCollapse]
  );

  const onToggleShowCurrentMarket: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.stopPropagation();
      setShowOnlyCurrentMarket(!showOnlyCurrentMarket);
    },
    [setShowOnlyCurrentMarket, showOnlyCurrentMarket]
  );

  const getTabTitle = useCallback(
    (tab: MarketPortfolioOptionEnum) => {
      const count = marketPortfolioOptionsCount[tab];
      switch (tab) {
        case MarketPortfolioOptionEnum.Positions:
          return `${t("positions")} ${count ? `(${count})` : ""}`;
        case MarketPortfolioOptionEnum.OpenOrders:
          return `${t("orders")} ${count ? `(${count})` : ""}`;
        case MarketPortfolioOptionEnum.History:
          return `${t("history")} ${count ? `(${count})` : ""}`;
        default:
          return "";
      }
    },
    [marketPortfolioOptionsCount, t]
  );

  const emptyContent = useCallback(
    (tab: MarketPortfolioOptionEnum) => {
      let text = "";
      switch (tab) {
        case MarketPortfolioOptionEnum.Positions:
          text = t("no_positions");
          break;
        case MarketPortfolioOptionEnum.OpenOrders:
          text = t("no_orders");
          break;
        case MarketPortfolioOptionEnum.History:
          return (
            <EmptyContent fillHeight>
              {t("no_history_past_7_days")}
              <Button
                style={{ marginTop: SPACING.two }}
                buttonTheme={ButtonThemeEnum.NEUTRAL3}
                onClick={() =>
                  navigate(`${PageEndpointEnum.PORTFOLIO}/trade-history`)
                }
              >
                {t("view_all_trade_history")}
              </Button>
            </EmptyContent>
          );
        default:
          break;
      }
      return <EmptyContent fillHeight>{text}</EmptyContent>;
    },
    [navigate, t]
  );

  return (
    <BottomBar
      onHeaderClick={onHeaderClick}
      currentTab={currentTab}
      style={style}
      segments={Object.keys(MarketPortfolioOptionEnum).map((k) => ({
        display: getTabTitle(
          MarketPortfolioOptionEnum[k as keyof typeof MarketPortfolioOptionEnum]
        ),
        value: MarketPortfolioOptionEnum[
          k as keyof typeof MarketPortfolioOptionEnum
        ] as MarketPortfolioOptionEnum,
        textColor:
          MarketPortfolioOptionEnum[
            k as keyof typeof MarketPortfolioOptionEnum
          ] === currentTab
            ? TEXT_COLORS.one
            : TEXT_COLORS.three,
      }))}
      onSelect={(k) => setCurrentTab(k as MarketPortfolioOptionEnum)}
      rightAccessories={
        <SelectedMarketToggleContainer onClick={onToggleShowCurrentMarket}>
          <span>{t("show_market_only")}</span>
          <Toggle
            isOn={showOnlyCurrentMarket}
            onToggle={onToggleShowCurrentMarket}
          />
          <Button
            buttonTheme={ButtonThemeEnum.HIGHLIGHT}
            onClick={onHeaderClick}
          >
            <Chevron direction={collapsed ? "up" : "down"} />
          </Button>
        </SelectedMarketToggleContainer>
      }
    >
      {accountApiKeyState !== AccountStateEnum.OK || showOnboarding ? (
        <EmptyContent fillHeight>
          <Button
            buttonTheme={ButtonThemeEnum.HIGHLIGHT}
            onClick={() => setShowConnectModal(true)}
          >
            {accountApiKeyState === AccountStateEnum.REQUIRE_PASSWORD
              ? t("unlock_trading")
              : accountApiKeyState === AccountStateEnum.OK
              ? t("continue_onboarding")
              : accountApiKeyState === AccountStateEnum.REQUIRE_REGISTER_SIGNING
              ? t("complete_sign_in")
              : t("connect_wallet")}
          </Button>
        </EmptyContent>
      ) : currentTab === MarketPortfolioOptionEnum.Positions ? (
        <Positions
          selectedMarketType={
            showOnlyCurrentMarket ? market.derivative : undefined
          }
          loading={accountDataLoading}
          positions={accountPositions || []}
          inLiquidation={!!accountData?.in_liquidation}
          instrumentMark={instrumentMark}
          emptyContent={emptyContent(MarketPortfolioOptionEnum.Positions)}
        />
      ) : currentTab === MarketPortfolioOptionEnum.OpenOrders ? (
        <Orders
          selectedMarketType={
            showOnlyCurrentMarket ? market.derivative : undefined
          }
          selectedAssetType={showOnlyCurrentMarket ? market.asset : undefined}
          loading={ordersLoading}
          orders={ordersData || []}
          emptyContent={emptyContent(MarketPortfolioOptionEnum.OpenOrders)}
        />
      ) : currentTab === MarketPortfolioOptionEnum.History ? (
        <TradeHistory
          isStickyHeader
          selectedMarketType={
            showOnlyCurrentMarket ? market.derivative : undefined
          }
          loading={tradesLoading}
          histories={tradeHistoryData?.trade_history || []}
          emptyContent={emptyContent(MarketPortfolioOptionEnum.History)}
        />
      ) : null}

      {/* {bottomBarContent} */}
    </BottomBar>
  );
}

export default TradingBottomBar;
