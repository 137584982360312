import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Alert } from "../../../assets/svg/alert.svg";
import { COLORS, PRIMARY_COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { PORTFOLIO_MARGIN_MINIMUM_EQUITY } from "../../../constants/precision/margin";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";
import { shortenAddress } from "../../../utils/strings";
import ConnectWalletModal from "../../ConnectWalletModal";
import DepositModal from "../../DepositWithdrawModal/DepositModal";
import TransferModal from "../../DepositWithdrawModal/TransferModal";
import WithdrawModal from "../../DepositWithdrawModal/WithdrawModal";
import AccountModal from "../Header/AccountModal";
import {
  AddressWrapper,
  ButtonContent,
  Indicator,
  MarginButton,
  MarginButtonWrapper,
  SignInAddress,
  SignInContent,
  SignInContentDetails,
  SignInTitle,
} from "./style";
import { AccountDropdownStateEnum } from "./type";
import { isPWA } from "../../../utils/pwa";
import StakingBadge from "../StakingBadge";
import useRewardsData from "../../../hooks/api/rewardsData/useRewardsData";
import { PageEndpointEnum } from "../../../enums/endpoint";

interface IAccountDropdownProps {
  onSyncPWA?: () => void;
}

function AccountDropdown({ onSyncPWA }: IAccountDropdownProps) {
  const {
    accountApiKeyState,
    accountSigningKeyState,
    account,
    isAccountImported,
  } = useContext(AuthContext);
  const { showConnectModal, setShowConnectModal } =
    useContext(ConnectWalletContext);
  const navigate = useNavigate();

  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);

  const { stakingBadge } = useRewardsData();
  const { data: accountData } = useGetAccount();
  const { t } = useTranslation("app", { keyPrefix: "shared.AccountDropdown" });
  const { isGeoblocked } = useContext(AuthContext);
  const location = useLocation();
  const accountDropdownState: AccountDropdownStateEnum = useMemo(() => {
    if (account) {
      return AccountDropdownStateEnum.WALLET_CONNECTED;
    }
    return AccountDropdownStateEnum.WALLET_DISCONNECTED;
  }, [account]);

  const buttonContent = useMemo(() => {
    switch (accountDropdownState) {
      case AccountDropdownStateEnum.WALLET_CONNECTED:
        if (isGeoblocked && location.pathname === PageEndpointEnum.AEVO) {
          return (
            <ButtonContent
              onClick={() => setShowAccountModal(true)}
              tradingEnabled={accountSigningKeyState === AccountStateEnum.OK}
            >
              {accountSigningKeyState === AccountStateEnum.OK &&
              accountData?.in_liquidation ? (
                <Indicator color={COLORS.negative.one} />
              ) : null}
              <div style={{ marginRight: stakingBadge ? SPACING.two : 0 }}>
                <StakingBadge stakingBadge={stakingBadge} />
              </div>
              {isPWA && isAccountImported
                ? t("sync_active")
                : shortenAddress(account || "")}
            </ButtonContent>
          );
        }
        if (
          accountApiKeyState !== AccountStateEnum.OK ||
          accountSigningKeyState !== AccountStateEnum.OK
        ) {
          return (
            <SignInContent
              inLiquidation={accountData?.in_liquidation}
              onClick={() => setShowConnectModal(true)}
            >
              <SignInContentDetails>
                <SignInTitle>{t("complete_sign_in")}</SignInTitle>
                <SignInAddress inLiquidation={accountData?.in_liquidation}>
                  {shortenAddress(account || "")}
                </SignInAddress>
              </SignInContentDetails>
            </SignInContent>
          );
        }
        return (
          <ButtonContent
            onClick={() => setShowAccountModal(true)}
            tradingEnabled={accountSigningKeyState === AccountStateEnum.OK}
          >
            {accountSigningKeyState === AccountStateEnum.OK ? (
              accountData?.in_liquidation ? (
                <Indicator color={COLORS.negative.one} />
              ) : null
            ) : (
              <Alert
                style={{ marginRight: `${SPACING.two}px` }}
                stroke={PRIMARY_COLORS.one}
              />
            )}
            <div style={{ marginRight: stakingBadge ? SPACING.two : 0 }}>
              <StakingBadge stakingBadge={stakingBadge} />
            </div>
            {isPWA && isAccountImported
              ? t("sync_active")
              : shortenAddress(account || "")}
          </ButtonContent>
        );
      default:
        return (
          <ButtonContent
            state="connectWallet"
            onClick={() => setShowConnectModal(true)}
          >
            {t("connect_wallet")}
          </ButtonContent>
        );
    }
  }, [
    accountDropdownState,
    isGeoblocked,
    location.pathname,
    accountApiKeyState,
    accountSigningKeyState,
    accountData?.in_liquidation,
    stakingBadge,
    isAccountImported,
    t,
    account,
    setShowConnectModal,
  ]);

  const onHideModal = useCallback(
    () => setShowConnectModal(false),
    [setShowConnectModal]
  );

  return (
    <>
      {showConnectModal && (
        <ConnectWalletModal show={showConnectModal} onHide={onHideModal} />
      )}
      {Number(accountData?.equity) >= PORTFOLIO_MARGIN_MINIMUM_EQUITY ? (
        <MarginButtonWrapper>
          <MarginButton
            onClick={() => navigate("/settings/portfolio-margin")}
            isActive={accountData?.portfolio}
          >
            {t("pm")}
          </MarginButton>
        </MarginButtonWrapper>
      ) : null}
      <AddressWrapper
        accountDropdownState={accountDropdownState}
        tradingEnabled={accountSigningKeyState === AccountStateEnum.OK}
      >
        {buttonContent}
        {account && (
          <>
            <AccountModal
              show={showAccountModal}
              onSyncPWA={onSyncPWA}
              onHide={() => setShowAccountModal(false)}
              onDepositClick={() => setShowDepositModal(true)}
              onWithdrawClick={() => setShowWithdrawModal(true)}
              onTransferClick={() => setShowTransferModal(true)}
              onContinueOnboarding={() => setShowConnectModal(true)}
              stakingBadge={stakingBadge}
            />
            {showDepositModal && (
              <DepositModal
                show={showDepositModal}
                onHide={() => {
                  setShowDepositModal((prev) => {
                    if (prev) {
                      setShowAccountModal(true);
                    }
                    return false;
                  });
                  setShowAccountModal(false);
                }}
              />
            )}
            {showWithdrawModal && (
              <WithdrawModal
                show={showWithdrawModal}
                onHide={() => {
                  setShowWithdrawModal((prev) => {
                    if (prev) {
                      setShowAccountModal(true);
                    }
                    return false;
                  });
                }}
              />
            )}
            {showTransferModal && (
              <TransferModal
                show={showTransferModal}
                onHide={() => {
                  setShowTransferModal((prev) => {
                    if (prev) {
                      setShowAccountModal(true);
                    }
                    return false;
                  });
                }}
              />
            )}
          </>
        )}
      </AddressWrapper>
    </>
  );
}

export default AccountDropdown;
