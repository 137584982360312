import { AxiosError } from "axios";
import { useContext } from "react";
import useSWR from "swr";
import { GetReferralHistory200Response } from "../../../codegen-api";
import { AuthContext } from "../../../contexts/AuthContext";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { authApi } from "../../../services/api/apiFetcher";

export const useReferralHistory = () => {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = authApi(apiKey, apiSecret);

  return useSWR<GetReferralHistory200Response, AxiosError>(
    apiKey ? [APIEndpointEnum.REFERRAL_HISTORY, apiKey] : undefined,
    async () => (await (await fetcher.getReferralHistory())()).data
  );
};
