import styled, { css } from "styled-components";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import {
  ButtonThemeEnum,
  buttonBgColor,
  buttonTextColor,
} from "../Buttons/styles";
import { RowActionButtonWrapper } from "../shared/RowActionButton/style";
import { TableWrapper } from "../shared/Table/style";

export const RowActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > ${RowActionButtonWrapper} {
    &:not(:last-of-type) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const ClosePositionButton = styled(RowActionButtonWrapper)`
  border-radius: ${SPACING.two}px;
  margin-right: ${SPACING.three}px;
  background-color: ${BACKGROUND_COLORS.five};

  &:hover {
    opacity: 0.8;
  }
`;

export const PositionsTableWrapper = styled(TableWrapper)<{
  isStrategyView?: boolean;
}>`
  height: 100%;
  overflow: auto !important;

  thead {
    position: sticky;
  }

  ${({ isStrategyView }) =>
    !isStrategyView &&
    css`
      th,
      td {
        &:last-child {
          padding-left: ${SPACING.two}px;
          padding-right: ${SPACING.two}px;
          position: sticky;
          right: 0;
          z-index: 1;
        }
      }
    `}

  // Action buttons
  tbody td {
    background: ${LAYER_COLORS.one};
  }
`;

export const SummaryTableRow = styled.tr<{ theme?: ButtonThemeEnum }>`
  background-color: ${({ theme }) =>
    theme !== undefined
      ? buttonBgColor[theme as ButtonThemeEnum]
      : COLORS.highlight.five} !important;
  color: ${({ theme }) =>
    theme !== undefined
      ? buttonTextColor[theme as ButtonThemeEnum]
      : COLORS.highlight.one};

  height: ${COMPONENTS.tableHeader}px !important;
  td {
    background-color: transparent !important;
  }
  &:hover {
    td {
      background-color: ${({ theme }) =>
        theme !== undefined
          ? buttonBgColor[theme as ButtonThemeEnum]
          : COLORS.highlight.five};
    }
  }
  cursor: default !important;
`;

export const PositionTableRow = styled.tr`
  cursor: pointer;
`;

export const HeaderColumn = styled.td`
  position: relative !important;
`;

export const ShareContainer = styled.button`
  margin-right: ${SPACING.one}px;
`;

export const AssetAndLabels = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
    margin-right: ${SPACING.two}px;
  }

  > div {
    display: flex;
    flex-direction: column;

    span {
      ${FONT_CSS.body.six}
      color: ${TEXT_COLORS.three};
    }

    span:first-child {
      ${FONT_CSS.body.five}
      color: ${TEXT_COLORS.one};
      margin-bottom: ${SPACING.one}px;

      svg {
        margin-left: ${SPACING.one}px;
      }
    }
  }
`;

export const AnimatedColumn = styled.td<{ expanded?: boolean }>`
  > div {
    display: flex;
    align-items: center;
    overflow: hidden;
    ${FONT_CSS.body.five}

    transition: all 0.2s ease-in-out;
    height: ${({ expanded }) => (expanded ? COMPONENTS.tableRow : 0)}px;
  }
`;

export const ExpiryColumn = styled(AnimatedColumn)`
  > div {
    align-items: center;
    color: ${TEXT_COLORS.two};
    margin-left: ${SPACING.three}px;
    padding-left: ${SPACING.four}px;
    border-left: 1px solid ${BORDER_COLORS.three};

    svg {
      margin-left: ${SPACING.one}px;
    }
  }
`;

export const PositionTitleWrapper = styled.div`
  height: 100%;
  display: flex;
  color: ${TEXT_COLORS.two};
  margin-left: ${SPACING.three + SPACING.four}px;
  padding-left: ${SPACING.four}px;
  border-left: 1px solid ${BORDER_COLORS.three};
`;

export const OptionNameTitle = styled.span`
  ${FONT_CSS.body.five}
  display: flex;
  align-items: center;
  color: ${TEXT_COLORS.one};
`;

export const OptionCell = styled.div``;
