import { AxiosError } from "axios";
import { useCallback, useContext } from "react";
import { GenericErrorResponse } from "../../../codegen-api";
import { AuthContext } from "../../../contexts/AuthContext";
import { authApi } from "../../../services/api/apiFetcher";
import { useReferralStatistics } from "./useReferralStatistics";

export const useClaimRewards = () => {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = authApi(apiKey, apiSecret);
  const { mutate } = useReferralStatistics();

  const claimReferralRewards = useCallback(async () => {
    try {
      await (
        await fetcher.postClaimReferralRewards()
      )().finally(() => mutate());
    } catch (error) {
      const genericResponseAxiosError
        = error as AxiosError<GenericErrorResponse>;
      throw Error(
        genericResponseAxiosError.response?.data?.error
          || "Error Claiming Rewards"
      );
    }
  }, [fetcher, mutate]);

  return {
    claimReferralRewards,
  };
};
