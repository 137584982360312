import { CSSProperties, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronRight } from "../../assets/svg/chevron-outline.svg";
import { ReactComponent as Info } from "../../assets/svg/info.svg";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import {
  BottomStepButtonContainer,
  Container,
  Label,
  StepButton,
  StepButtonContainer,
  TopStepButtonContainer,
} from "./style";
import { AuthContext } from "../../contexts/AuthContext";

interface IStepButtonsProps {
  rightTitle?: string | JSX.Element;
  alternateRightTitle?: string | JSX.Element;
  onClickRight?: () => void;
  onClickRightAlternate?: () => void;
  onClickLeft?: () => void;
  leftHidden?: boolean;
  showDisconnect?: boolean;
  info?: {
    onInfoClick: () => void;
    disabled?: boolean;
  };
  style?: CSSProperties;

  leftButtonTheme?: ButtonThemeEnum;
  rightButtonTheme?: ButtonThemeEnum;
}

function StepButtons({
  rightTitle,
  alternateRightTitle,
  onClickRight,
  onClickRightAlternate,
  leftHidden,
  showDisconnect,
  onClickLeft,
  info,
  style,
  leftButtonTheme = ButtonThemeEnum.NEUTRAL2,
  rightButtonTheme = ButtonThemeEnum.NEUTRAL2,
}: IStepButtonsProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "StepButtons",
  });

  const { disconnectAccount } = useContext(AuthContext);
  return (
    <Container style={style}>
      <StepButtonContainer>
        <TopStepButtonContainer>
          {!leftHidden && (
            <StepButton
              buttonTheme={leftButtonTheme}
              direction="left"
              disabled={!onClickLeft}
              onClick={onClickLeft}
            >
              <ChevronRight />
            </StepButton>
          )}
          <StepButton
            buttonTheme={rightButtonTheme}
            direction="right"
            disabled={!onClickRight}
            onClick={onClickRight}
            style={{
              width: "100%",
            }}
          >
            {rightTitle && <Label>{rightTitle}</Label>}
          </StepButton>
          {alternateRightTitle && (
            <StepButton
              buttonTheme={rightButtonTheme}
              direction="right"
              disabled={!onClickRightAlternate}
              onClick={onClickRightAlternate}
            >
              {alternateRightTitle && <Label>{alternateRightTitle}</Label>}
            </StepButton>
          )}
          {info && (
            <Button
              buttonTheme={ButtonThemeEnum.NEUTRAL2}
              disabled={info.disabled}
              onClick={info.onInfoClick}
            >
              <Info />
            </Button>
          )}
        </TopStepButtonContainer>
        {showDisconnect && (
          <BottomStepButtonContainer>
            <StepButton
              buttonTheme={rightButtonTheme}
              direction="right"
              fullWidth
              onClick={() => disconnectAccount()}
            >
              <Label>{t("disconnect")}</Label>
            </StepButton>
          </BottomStepButtonContainer>
        )}
      </StepButtonContainer>
    </Container>
  );
}

export default StepButtons;
