import currency from "currency.js";
import moment from "moment";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSortBy, useTable } from "react-table";
import { ReactComponent as Bonus } from "../../assets/svg/dollar.svg";
import { ReactComponent as Discount } from "../../assets/svg/percent.svg";
import { GetReferralRewardsHistory200ResponseReferralRewardsHistoryInner } from "../../codegen-api";
import { Spinner } from "../../components/shared/Spinner";
import { SpinnerContainerWrapper } from "../../components/shared/Spinner/style";
import { DefaultCellForColumn } from "../../components/shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../components/shared/Table/DefaultHeaderForColumn";
import { Align } from "../../components/shared/Table/style";
import { TEXT_COLORS } from "../../constants/design/colors";
import { AccountStateEnum, AuthContext } from "../../contexts/AuthContext";
import { useRewardHistory } from "../../hooks/api/referrals/useRewardHistory";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { nanosToSeconds } from "../../utils/date";
import { EmptyContent, RewardIcon, RewardReferralTableWrapper } from "./style";

interface IRewardTableProps {
  isMobileScreen?: boolean;
}

export function RewardTable({ isMobileScreen }: IRewardTableProps) {
  const { data: rewardHistoryData, isValidating: rewardHistoryLoading } =
    useRewardHistory();
  const { accountApiKeyState } = useContext(AuthContext);
  const modifiedRewardsHistory = useMemo(
    () => rewardHistoryData?.referral_rewards_history || [],
    [rewardHistoryData]
  );
  const { t } = useTranslation("app", {
    keyPrefix: "pages.ReferralsTab.RewardTable",
  });

  const REWARD_TYPE_CONSTANTS: Record<
    string,
    { title: string; icon: JSX.Element }
  > = useMemo(
    () => ({
      referral_bonus: {
        title: t("referral_bonus"),
        icon: <Discount key="discount" />,
      },
      referee_discount: {
        title: t("referral_discount"),
        icon: <Bonus key="bonus" />,
      },
    }),
    [t]
  );

  const columns: ITableColumn<GetReferralRewardsHistory200ResponseReferralRewardsHistoryInner>[] =
    useMemo(
      () => [
        {
          title: t("reward_type"),
          accessor: "reward_type",
          Cell: ({ value }: any) => (
            <Align align={"left"}>
              <RewardIcon>{REWARD_TYPE_CONSTANTS[value].icon}</RewardIcon>
              {REWARD_TYPE_CONSTANTS[value].title}
            </Align>
          ),
        },
        {
          title: t("date"),
          accessor: "created_timestamp",
          align: "right",
          Cell: ({ value }: any) => (
            <Align align={"right"} style={{ color: TEXT_COLORS.two }}>
              {moment.unix(nanosToSeconds(value)).format("DD MMM YY")}
            </Align>
          ),
        },
        {
          title: t("reward_earned"),
          accessor: "rewards",
          align: "right",
          Cell: ({ value }: any) => (
            <Align align={"right"}>{currency(value).format()}</Align>
          ),
        },
      ],
      [REWARD_TYPE_CONSTANTS, t]
    );

  // eslint-disable-next-line operator-linebreak
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: modifiedRewardsHistory,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        },
        autoResetSortBy: false,
      } as any,
      useSortBy
    );

  return (
    <RewardReferralTableWrapper>
      {
        // eslint-disable-next-line no-nested-ternary
        modifiedRewardsHistory.length < 1 ? (
          rewardHistoryLoading && !modifiedRewardsHistory.length ? (
            <SpinnerContainerWrapper>
              <Spinner />
            </SpinnerContainerWrapper>
          ) : (
            <EmptyContent>
              {accountApiKeyState === AccountStateEnum.OK
                ? t("no_history_desc")
                : t("connect_wallet_desc")}
            </EmptyContent>
          )
        ) : (
          <table {...getTableProps()}>
            {!isMobileScreen ? (
              <thead>
                {headerGroups.map((headerGroup) => (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      // <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      // eslint-disable-next-line react/jsx-key
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            ) : null}
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                const { ...rowProps } = row.getRowProps();
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...rowProps}>
                    {row.cells.map((cell) => (
                      // eslint-disable-next-line react/jsx-key
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )
      }
    </RewardReferralTableWrapper>
  );
}
