// Hook that handles all pwa flow between desktop/mobile
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { LINKS } from "../../constants/links";
import { PageEndpointEnum } from "../../enums/endpoint";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import {
  IDecryptedAuthInfo,
  IEncryptedAuthInfo,
} from "../../interfaces/AuthInfo";
import {
  decryptEncryptedAccountInfo,
  encryptAccountInfo,
} from "../../utils/encryption/accountPasswordEncryption";

// eg. /pwa?
export const pwaURLPrefix = `${PageEndpointEnum.PWA}?`;

interface IPWAQueryInfo {
  account: string;
  encryptedBundle: string;
  salt: string;
  iv: string;
}

const pwaQueryInfoToQueryString = (pwaQueryInfo: IPWAQueryInfo) => {
  const query = Object.keys(pwaQueryInfo)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          pwaQueryInfo[key as keyof typeof encryptAccountInfo]
        )}`
    )
    .join("&");
  return query;
};

export const usePWAFlow = () => {
  // ====== DESKTOP ======
  // 1. Generate QR Code URL
  const generateEncryptedURL = useCallback(
    (accountInfo: IDecryptedAuthInfo) => {
      // 1. Generate encryption password
      const password = String(Math.floor(Math.random() * 900000) + 100000);

      // 2. Encrypt it with the password
      const { encryptedText, iv, salt } = encryptAccountInfo(
        accountInfo,
        password
      );
      const pwaQueryInfo: IPWAQueryInfo = {
        account: accountInfo.account,
        encryptedBundle: encryptedText,
        salt,
        iv,
      };

      const query = pwaQueryInfoToQueryString(pwaQueryInfo);
      return {
        // 3. Append encrypted info json txt to url, this will be used to access directly through mobile eg:
        // https://app.aevo.xyz/pwa?pwaData={jsonTxt}
        url: `${LINKS.app}${pwaURLPrefix}${query}`,
        password,
      };
    },
    []
  );

  // ======== MOBILE ========
  const { search } = useLocation();

  const pwaInfo = useMemo(() => {
    if (localStorage.getItem(LocalStorageKeyEnum.PWA_PROCESSED)) {
      return undefined;
    }

    const queryParams = new URLSearchParams(search);

    const obj: any = {};
    queryParams.forEach((value, key) => {
      obj[key] = value;
    });

    const queryInfo = obj as IPWAQueryInfo;

    if (
      queryInfo.account &&
      queryInfo.encryptedBundle &&
      queryInfo.iv &&
      queryInfo.salt
    ) {
      try {
        const parsed: IEncryptedAuthInfo = {
          ...queryInfo,
          passwordProtected: true,
          encrypted: true,
        };
        return {
          query: pwaQueryInfoToQueryString(queryInfo),
          parsed,
        };
      } catch (error) {
        // Error
      }
    }
    return undefined;
  }, [search]);

  /**
   * Decrypt the pwaData with a given password.
   * This function throws an error if decryption failed. Make sure to handle it.
   * @throws {Exception}
   */
  const decryptData = useCallback(
    (password: string) => {
      if (!pwaInfo) {
        return undefined;
      }
      const decrypted = decryptEncryptedAccountInfo(pwaInfo.parsed, password);
      return decrypted;
    },
    [pwaInfo]
  );

  // This adds a flag in local storage that prevents pwaInfo from being returned.
  const setProcessedPWA = useCallback((processed: boolean) => {
    if (processed) {
      localStorage.setItem(LocalStorageKeyEnum.PWA_PROCESSED, "true");
    } else {
      localStorage.removeItem(LocalStorageKeyEnum.PWA_PROCESSED);
    }
  }, []);

  return {
    pwaInfo,
    setProcessedPWA,
    generateEncryptedURL,
    decryptData,
  };
};
