import currency from "currency.js";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logo/logo_text_white.svg";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { GetReferralStatistics200Response } from "../../codegen-api";
import { useClaimRewards } from "../../hooks/api/referrals/useClaimRewards";
import { useToast } from "../../hooks/toast";
import { formatCompactCurrency } from "../../utils/math";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import { BaseModal } from "../BaseModal";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import BackgroundVideoFX from "../shared/BackgroundVideoFX/BackgroundVideoFX";
import {
  RewardsEarnedWrapper,
  RewardsFooter,
  RewardsStat,
  RewardsStatsWrapper,
} from "./style";

interface IClaimRewardsModalProps {
  show: boolean;
  onHide: () => void;
  data?: GetReferralStatistics200Response;
}

function ClaimRewardsModal({ show, onHide, data }: IClaimRewardsModalProps) {
  const [isClaiming, setIsClaiming] = useState<boolean>(false);

  const { claimReferralRewards } = useClaimRewards();
  const { addToast, addErrorToast } = useToast();
  const { t } = useTranslation("app", { keyPrefix: "ClaimRewardsModal" });
  const { t: apiErrors } = useTranslation("apiErrors");

  useEffect(() => {
    setIsClaiming(false);
  }, [show]);

  const onClaim = useCallback(async () => {
    try {
      setIsClaiming(true);
      await claimReferralRewards();
      addToast(
        {
          header: t("rewards_claimed"),
          status: ToastStatusEnum.SUCCESS,
          type: ToastEnum.SIMPLE,
        },
        3000
      );
    } catch (error: any) {
      addErrorToast(
        t("claim_rewards"),
        apiErrors(error.message) || t("try_again")
      );
      setIsClaiming(false);
    } finally {
      onHide?.();
      setIsClaiming(false);
    }
  }, [addErrorToast, addToast, claimReferralRewards, onHide, t, apiErrors]);

  return (
    <BaseModal
      show={show}
      onHide={onHide}
      style={{ width: 311 }}
      showHeader={false}
    >
      <RewardsEarnedWrapper
        key={String(isClaiming)}
        transition={{
          duration: 0.5,
          ease: "easeInOut",
        }}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0,
        }}
      >
        {isClaiming ? (
          <>
            <img src={logo} alt="logo" />
            <BackgroundVideoFX />
          </>
        ) : (
          <>
            <span>{t("total_unclaimed_rewards")}</span>
            <div>
              {formatCompactCurrency(
                Number(data?.total_rewards_unclaimed || 0)
              )}
            </div>
          </>
        )}
      </RewardsEarnedWrapper>
      <RewardsStatsWrapper>
        <RewardsStat>
          <span>{t("referral_bonus")}</span>
          <span>
            {currency(
              Number(data?.total_referral_bonus_unclaimed || 0)
            ).format()}
          </span>
        </RewardsStat>
        <RewardsStat>
          <span>{t("referral_discount")}</span>
          <span>
            {currency(
              Number(data?.total_referee_discount_unclaimed || 0)
            ).format()}
          </span>
        </RewardsStat>
      </RewardsStatsWrapper>
      <RewardsFooter>
        <Button
          buttonTheme={ButtonThemeEnum.PRIMARY}
          disabled={isClaiming}
          onClick={onClaim}
        >
          {isClaiming ? t("claiming") : t("claim")}
        </Button>
        <Button buttonTheme={ButtonThemeEnum.NEUTRAL2} onClick={onHide}>
          <Close />
        </Button>
      </RewardsFooter>
    </BaseModal>
  );
}

export default ClaimRewardsModal;
