import { useMemo } from "react";
import { StakingBadge as Badge } from "../../../assets/styledSvg/stakingBadge";

import { StakingBadgeWrapper } from "./style";
import { BadgeTier } from "../../../hooks/api/rewardsData/useRewardsData";

function StakingBadge({
  size = 24,
  stakingBadge,
}: {
  size?: number;
  stakingBadge?: BadgeTier;
}) {
  const fill = useMemo(() => {
    switch (stakingBadge) {
      case "Bronze":
        return "#FF3D00";
      case "Silver":
        return "#B0B0B0";
      case "Gold":
        return "#FB9700";
      case "Platinum":
        return "#E5E4E2";
      case "Diamond":
        return "#1E90FF";
      default:
        return "#FF3D00";
    }
  }, [stakingBadge]);

  if (!stakingBadge) {
    return null;
  }
  return (
    <StakingBadgeWrapper>
      <Badge color={fill} width={size} height={size} />
    </StakingBadgeWrapper>
  );
}

export default StakingBadge;
