import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { coinbaseWallet } from "../../utils/wallet/connectors/coinbaseWallet";
import { metaMask } from "../../utils/wallet/connectors/metamask";
import { walletConnectV2 } from "../../utils/wallet/connectors/walletConnectV2";
import { WalletEnum } from "../../utils/wallet/types";

const useEagerConnect = () => {
  const { isActive } = useWeb3React();
  const [tried, setTried] = useState(false);

  useEffect(() => {
    if (tried) {
      return;
    }

    const lastConnectedWalletString = localStorage.getItem(LocalStorageKeyEnum.LAST_CONNECTED_WALLET);
    const lastConnectedWallet: WalletEnum | undefined = Number.isNaN(Number(lastConnectedWalletString))
      ? undefined
      : Number(lastConnectedWalletString) as WalletEnum;

    // Connects to last connected connector if valid
    const promises: Promise<any>[] = [];
    switch (lastConnectedWallet) {
      case WalletEnum.METAMASK:
        promises.push(metaMask.connectEagerly?.());
        break;
      case WalletEnum.WALLETCONNECT:
        promises.push(walletConnectV2.connectEagerly?.());
        break;
      case WalletEnum.WALLETLINK:
        promises.push(coinbaseWallet.connectEagerly?.());
        break;
      default:
        break;
    }
    Promise.all(promises).catch(() => {
      setTried(true);
    });
  }, [tried]); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && isActive) {
      setTried(true);
    }
  }, [tried, isActive]);

  return tried;
};
export default useEagerConnect;
