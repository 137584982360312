import { useContext, useMemo } from "react";
import currency from "currency.js";
import { BigNumber, ethers } from "ethers";
import useSubgraphData from "../subgraph/useSubgraphData";
import { useEmissions } from "../emissions/useEmissions";
import {
  calculateLuckyBoost,
  calculatePrelaunchBoost,
  calculateStakingApr,
  calculateStakingRewardsAndHistory,
  getCurrentEpoch,
  getUnlockDateOfEpoch,
} from "../../../utils/math";
import { IStat } from "../../../interfaces/Toast";
import { AuthContext } from "../../../contexts/AuthContext";

export type BadgeTier = "Bronze" | "Silver" | "Gold" | "Platinum" | "Diamond";

const useRewardsData = () => {
  const { account } = useContext(AuthContext);
  const { data: emissions } = useEmissions();
  const decimals = 18;
  const { accountStakingData, vaultStakingData, fetchSubgraphData } =
    useSubgraphData();

  const [confirmedStakingRewards, stakingEpochRewardsHistory] = useMemo(() => {
    if (!emissions || !account) {
      return [undefined, undefined];
    }

    const stakingRewardsAndHistory = calculateStakingRewardsAndHistory(
      accountStakingData?.epochStakeAmounts || [],
      vaultStakingData?.epochStakeAmounts || [],
      emissions,
      account
    );
    return [
      stakingRewardsAndHistory.confirmedRewards,
      stakingRewardsAndHistory.rewardsHistoryArray,
    ];
  }, [
    account,
    accountStakingData?.epochStakeAmounts,
    emissions,
    vaultStakingData?.epochStakeAmounts,
  ]);

  const unstakeTimings: IStat[] = useMemo(() => {
    const currentEpoch = getCurrentEpoch();
    const accountStakeAmounts = accountStakingData?.epochStakeAmounts || [];
    return (
      accountStakeAmounts
        .filter((stake) => stake.epoch > currentEpoch - 9)
        // only show epochs you cannot unstake yet
        .map((stake) => ({
          label: getUnlockDateOfEpoch(stake.epoch),
          value: currency(
            ethers.utils.formatUnits(stake.stakedAmount, decimals),
            {
              symbol: "",
            }
          ).format(),
        }))
    );
  }, [accountStakingData]);

  // total staked amount for an account
  const stakedAmount = useMemo(() => {
    if (!accountStakingData) {
      return undefined;
    }
    return ethers.utils.parseUnits(accountStakingData.totalStakedAmount, 0);
  }, [accountStakingData]);

  // total staked amount for in the vault
  const totalStakedAmount = useMemo(() => {
    if (!vaultStakingData) {
      return undefined;
    }
    return ethers.utils.parseUnits(vaultStakingData.totalStakedAmount, 0);
  }, [vaultStakingData]);

  const stakingApr = useMemo(() => {
    if (!emissions || !totalStakedAmount) {
      return undefined;
    }

    return calculateStakingApr(emissions, totalStakedAmount);
  }, [emissions, totalStakedAmount]);

  const unstakableAmount = useMemo(() => {
    const currentEpoch = getCurrentEpoch();
    const accountStakeAmounts = accountStakingData?.epochStakeAmounts || [];
    return accountStakeAmounts
      .filter(
        (stake) => stake.epoch <= currentEpoch - 9 && stake.unstakeEpoch === 999 // 999 is default number from subgraph
      ) // only show epochs you can unstake from
      .reduce((total, stake) => {
        const amount = ethers.utils.formatUnits(stake.stakedAmount, decimals);
        return total + parseFloat(amount);
      }, 0); // Sum up all unstakable amounts
  }, [accountStakingData]);

  const prelaunchBoost = useMemo(() => {
    if (!stakedAmount) {
      return undefined;
    }
    return calculatePrelaunchBoost(
      Number(ethers.utils.formatUnits(stakedAmount))
    );
  }, [stakedAmount]);

  const luckyBoostChance = useMemo(() => {
    if (!stakedAmount) {
      return 13.5;
    }
    return calculateLuckyBoost(Number(ethers.utils.formatUnits(stakedAmount)));
  }, [stakedAmount]);

  const stakingBadge: BadgeTier | undefined = useMemo(() => {
    const stakedAmountNumber = Number(
      ethers.utils.formatUnits(stakedAmount || 0)
    );
    if (stakedAmountNumber < 1) {
      return undefined;
    }
    if (stakedAmountNumber >= 1 && stakedAmountNumber < 101) {
      return "Bronze";
    }
    if (stakedAmountNumber < 1001) {
      return "Silver";
    }
    if (stakedAmountNumber < 10001) {
      return "Gold";
    }
    if (stakedAmountNumber < 101000) {
      return "Platinum";
    }
    return "Diamond";
  }, [stakedAmount]);

  return {
    confirmedStakingRewards,
    fetchStakingRewards: fetchSubgraphData,
    stakingEpochRewardsHistory,
    unstakeTimings,
    stakedAmount,
    totalStakedAmount,
    stakingApr,
    unstakableAmount,
    isAccountStaked: stakedAmount && stakedAmount.gt(BigNumber.from(0)),
    prelaunchBoost,
    luckyBoostChance,
    stakingBadge,
  };
};

export default useRewardsData;
