import { motion } from "framer-motion";
import ReactSlider from "react-slider";
import styled, { css } from "styled-components";
import {
  FIELD_COLORS,
  LAYER_COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS, FONT_STYLE } from "../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../constants/design/spacing";

export const RewardsEarnedWrapper = styled(motion.div)`
  padding: ${SPACING.five}px;
  background: ${LAYER_COLORS.two};
  text-align: center;
  border-radius: ${BORDER_RADIUS.five}px;
  margin-bottom: ${SPACING.three}px;
  height: 116px;
  overflow: hidden;
  position: relative;

  > * {
    margin: auto;
  }

  > span {
    color: ${TEXT_COLORS.three};
    ${FONT_CSS.label.three};
  }

  > div {
    color: ${TEXT_COLORS.one};
    ${FONT_CSS.data.one}
  }

  > video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0.4;
    overflow: hidden;
    right: 0;
    bottom: 0;
    z-index: 100;
  }

  > img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
  }
`;

export const RewardsFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: ${SPACING.two}px;
`;

export const RewardsStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RewardsStat = styled.div`
  display: flex;
  justify-content: space-between;
  ${FONT_CSS.label.one};

  > span {
    &:not(:last-of-type) {
      margin-bottom: ${SPACING.three}px;
    }

    &:first-of-type {
      color: ${TEXT_COLORS.three};
    }

    &:last-of-type {
      color: ${TEXT_COLORS.one};
    }
  }
`;

export const ClaimSliderWrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  height: 48px;
  border-radius: ${BORDER_RADIUS.five}px;
  position: relative;
  background-color: ${FIELD_COLORS.one};

  > span {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    align-self: center;
    z-index: 100;
    width: fit-content;
    ${FONT_STYLE.label.one};
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

export const ClaimSlider = styled(ReactSlider)<{ transitionSlider?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: grab;
  position: relative;

  ${({ transitionSlider }) =>
    transitionSlider &&
    css`
      div {
        transition: all 0.2s ease-in-out;
      }
    `}

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;

export const StyledTrack = styled.div<{ index: number; color?: string }>`
  top: 0;
  bottom: 0;
  border-radius: ${BORDER_RADIUS.five}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledThumb = styled(motion.div)<{ width: number }>`
  border-radius: ${BORDER_RADIUS.five}px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SPACING.twoHalf}px;
  height: calc(100% - ${SPACING.one * 2}px);
  margin: ${SPACING.one}px;
  position: absolute;
  left: 0 !important;
  width: ${({ width }) => width * 2}%;
  background-color: ${PRIMARY_COLORS.one};
  transition: 0.01s all ease-in-out;
  max-width: calc(100% - ${SPACING.one * 2}px);
  z-index: 200;

  &:focus-visible {
    outline: none;
  }

  div {
    position: absolute;
    right: ${SPACING.twoHalf}px;
    height: 8px;
    width: 1px;
    background-color: ${TEXT_COLORS.one};
  }
`;
