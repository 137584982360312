import styled from "styled-components";
import {
  BORDER_COLORS,
  COLORS,
  MODAL_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";
import { AnnouncementType } from "./Announcement";
import { flashingCSS } from "../style";

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: calc(${COMPONENTS.header.main}px);
  right: ${SPACING.two}px;
  z-index: 999;
`;

export const AnnouncementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.two}px;
`;

export const AnnouncementContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AnnouncementTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.three}px;
`;

export const AnnouncementBanner = styled.div<{ show?: boolean }>`
  width: 350px;
  background-color: ${MODAL_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: ${BORDER_RADIUS.two}px;
  color: ${TEXT_COLORS.two};
  transition: 0.45s all ease-out;
  font-family: BananaGrotesk;
  text-align: left;
  ${FONT_CSS.header.seven}
`;

export const BannerText = styled.div<{ type: AnnouncementType }>`
  ${FONT_CSS.body.three}
  color: ${({ type }) =>
    type === "alert" ? COLORS.blue.one : TEXT_COLORS.one};
`;

export const CloseButton = styled.button`
  min-height: ${COMPONENTS.modalHeaderHeight}px;
  width: ${COMPONENTS.modalHeaderHeight}px;
  border-left: 1px solid ${BORDER_COLORS.one};
`;

export const TopContent = styled.div`
  display: flex;
`;

export const BottomContent = styled.div`
  border-top: 1px solid ${BORDER_COLORS.one};
  padding: ${SPACING.two}px;
`;

export const AnnouncementHeader = styled.div<{ isAlert: boolean }>`
  ${({ isAlert }) => !isAlert && `height: ${COMPONENTS.modalHeaderHeight}px;`}
  display: flex;
  flex: 1;
  padding: ${SPACING.three}px;
  align-items: center;
  gap: ${SPACING.two}px;
`;

export const Indicator = styled.div<{ isAlert: boolean }>`
  min-width: 6px;
  min-height: 6px;
  border-radius: ${BORDER_RADIUS.two}px;
  background-color: ${({ isAlert }) =>
    isAlert ? COLORS.negative.one : COLORS.blue.one};
  ${flashingCSS}
`;
