import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { Button } from "../../components/Buttons/styles";
import {
  BORDER_COLORS,
  COLORS,
  FIELD_COLORS,
  ICON_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../constants/design/spacing";
import { TableWrapper } from "../../components/shared/Table/style";

export const Container = styled(motion.div)<{ $isMobileScreen?: boolean }>`
  padding-top: ${({ $isMobileScreen }) =>
    $isMobileScreen ? SPACING.two : SPACING.five}px;
  padding-bottom: ${({ $isMobileScreen }) =>
    $isMobileScreen ? SPACING.three : SPACING.five}px;
`;

export const ReferralStatsWrapper = styled.div<{ $isMobileScreen?: boolean }>`
  margin-top: ${SPACING.four}px;
  width: 100%;
  ${({ $isMobileScreen }) =>
    $isMobileScreen
      ? css``
      : css`
          display: grid;
          grid-template-columns: repeat(4, 1fr);
        `};
`;

export const ReferralStat = styled.div`
  > span {
    color: ${TEXT_COLORS.three};
    ${FONT_CSS.label.three}
  }

  > p {
    margin-top: ${SPACING.two}px;
    ${FONT_CSS.data.two}
  }
`;

export const ReferralLinkWrapper = styled.div<{ $isMobileScreen?: boolean }>`
  margin-top: ${SPACING.four}px;
  display: grid;
  grid-gap: ${SPACING.three}px;

  ${({ $isMobileScreen }) =>
    $isMobileScreen
      ? css`
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 1fr;
        `
      : css`
          grid-template-columns: 1fr 160px;

          > ${Button} {
            width: 160px;
            display: inline-block;
          }
        `}
  align-items: center;
`;

export const ReferralLink = styled.div`
  display: flex;
  border-radius: ${BORDER_RADIUS.five}px;
  justify-content: space-between;
  padding: ${SPACING.two}px;
  background-color: ${FIELD_COLORS.one};
  font-size: ${FONT_SIZE.two};

  > * {
    margin: auto 0;
  }

  > p {
    color: ${TEXT_COLORS.three};

    > span {
      color: ${TEXT_COLORS.one};
    }
  }

  > button {
    border: 1px solid transparent;
    width: 32px;
    height: 32px;
    background-color: ${FIELD_COLORS.two};
    border-radius: ${BORDER_RADIUS.two}px;
    color: ${TEXT_COLORS.one};
    transition: 0.2s all ease-in-out;

    &:hover {
      border: 1px solid ${BORDER_COLORS.five};
    }
  }
`;

export const TableContainer = styled.div`
  margin-top: ${SPACING.five}px;
`;

export const EmptyContent = styled.div`
  padding-top: ${SPACING.four}px;
  color: ${TEXT_COLORS.two};
  font-size: ${FONT_SIZE.two};
`;

export const RewardIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${COLORS.neutral.six};
  margin-right: ${SPACING.two}px;
  border-radius: 50%;
  display: flex;

  > svg {
    margin: auto;
    stroke: ${ICON_COLORS.one};
  }
`;

export const TraderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-of-type {
    color: ${TEXT_COLORS.one};
  }
`;

export const RewardReferralTableWrapper = styled(TableWrapper)`
  table {
    margin-top: ${SPACING.two}px;
    border-collapse: separate;
    border-spacing: 0;

    thead {
      tr {
        height: 100%;
      }
      border-bottom: ${SPACING.two}px solid transparent;
    }

    th {
      padding: 0;
      background-color: transparent;
    }

    tbody {
      tr {
        height: ${COMPONENTS.portfolioTableRow}px;
        border: none;
        background-color: transparent;
      }
      td {
        background: transparent !important;
        padding: 0;
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

export const VideoContainer = styled.div<{ isMobileScreen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 12px;
  overflow: hidden;
  background: ${LAYER_COLORS.four};

  height: 160px;

  video {
    opacity: 0.12;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ isMobileScreen }) =>
    isMobileScreen
      ? css`
          height: 96px;
        `
      : css`
          height: 160px;
          margin: ${SPACING.five}px auto;
        `}
`;

export const HeaderTitle = styled.div`
  ${FONT_CSS.header.five}
  color: ${TEXT_COLORS.one};
  margin: 0;
  margin-bottom: ${SPACING.two}px;
`;

export const HeaderContainer = styled.div<{ $isMobileScreen?: boolean }>`
  position: relative;
  z-index: 1;

  overflow: hidden;

  > span {
    ${FONT_CSS.body.three};
    color: ${TEXT_COLORS.two};

    a {
      color: ${TEXT_COLORS.three};
      text-decoration: none;
      transition: 0.2s all ease-in-out;

      > svg {
        transition: 0.2s all ease-in-out;
        stroke: ${TEXT_COLORS.three};
      }

      &:hover {
        color: ${TEXT_COLORS.one};

        > svg {
          stroke: ${TEXT_COLORS.one};
        }
      }
    }
  }
`;
