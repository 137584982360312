import { motion } from "framer-motion";
import styled from "styled-components";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

// STYLES
export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: ${SPACING.three}px;
  background: ${COLORS.background.one};
  overflow-y: scroll;

  display: grid;
  grid-template-rows: repeat(4, max-content);
  grid-gap: ${SPACING.five}px;
`;

export const NavItem = styled.button`
  ${FONT_CSS.header.five}
  width: 100%;
  text-align: left;

  a,
  button {
    color: ${TEXT_COLORS.one};
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  &:not(:last-of-type) {
    margin-bottom: ${SPACING.three}px;
  }

  svg {
    width: 28px;
    height: 28px;
    margin-left: ${SPACING.one}px;
  }
`;

export const SubNavItem = styled.button`
  ${FONT_CSS.header.seven}
  color: ${TEXT_COLORS.three};
  width: 100%;
  text-align: left;

  &:not(:last-of-type) {
    margin-bottom: ${SPACING.three}px;
  }
  > svg {
    stroke: ${TEXT_COLORS.three};
  }
`;

export const NavItems = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
  width: 100%;
  text-align: left;

  > * {
    padding-left: ${SPACING.two}px;
  }
`;
