import styled, { css } from "styled-components";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";

export const PageWrapper = styled.div<{ isMobileScreen?: boolean }>`
  margin: 0 ${SPACING.two}px;
  height: calc(100vh - ${COMPONENTS.header.main + COMPONENTS.footer.desktop}px);
  overflow-y: scroll;
  ${({ isMobileScreen }) =>
    isMobileScreen
      ? css`
          padding: ${SPACING.three}px;
          padding-bottom: ${SPACING.one}px;
        `
      : css`
          > * {
            height: fit-content;
            width: 656px;
            max-width: 100%;
            margin: auto;
          }
        `}
`;

export const PageHeader = styled.div<{ isMobileScreen?: boolean }>`
  > *:not(:last-child) {
    ${({ isMobileScreen }) =>
      isMobileScreen
        ? css`
            margin-bottom: ${SPACING.three}px;
          `
        : css`
            margin-bottom: ${SPACING.five}px;
          `}
  }
`;
