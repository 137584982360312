import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import moment from "moment";
import apiErrors_en from "./en/apiErrors.json";
import tooltips_en from "./en/tooltips.json";
import formErrors_en from "./en/formErrors.json";
import app_en from "./en/app.json";

import apiErrors_cn from "./cn/apiErrors.json";
import tooltips_cn from "./cn/tooltips.json";
import formErrors_cn from "./cn/formErrors.json";
import app_cn from "./cn/app.json";

import apiErrors_ru from "./ru/apiErrors.json";
import tooltips_ru from "./ru/tooltips.json";
import formErrors_ru from "./ru/formErrors.json";
import app_ru from "./ru/app.json";

import apiErrors_tr from "./tr/apiErrors.json";
import tooltips_tr from "./tr/tooltips.json";
import formErrors_tr from "./tr/formErrors.json";
import app_tr from "./tr/app.json";

import apiErrors_kr from "./kr/apiErrors.json";
import tooltips_kr from "./kr/tooltips.json";
import formErrors_kr from "./kr/formErrors.json";
import app_kr from "./kr/app.json";

import apiErrors_vn from "./vn/apiErrors.json";
import tooltips_vn from "./vn/tooltips.json";
import formErrors_vn from "./vn/formErrors.json";
import app_vn from "./vn/app.json";

import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import "moment/locale/ru";
import "moment/locale/tr";
import "moment/locale/ko";
import "moment/locale/vi";

export type Language = "en" | "cn" | "ru" | "tr" | "kr" | "vn";

export const resources = {
  en: {
    apiErrors: apiErrors_en,
    tooltips: tooltips_en,
    app: app_en,
    formErrors: formErrors_en,
  },
  cn: {
    apiErrors: apiErrors_cn,
    tooltips: tooltips_cn,
    app: app_cn,
    formErrors: formErrors_cn,
  },
  ru: {
    apiErrors: apiErrors_ru,
    tooltips: tooltips_ru,
    app: app_ru,
    formErrors: formErrors_ru,
  },
  tr: {
    apiErrors: apiErrors_tr,
    tooltips: tooltips_tr,
    app: app_tr,
    formErrors: formErrors_tr,
  },
  kr: {
    apiErrors: apiErrors_kr,
    tooltips: tooltips_kr,
    app: app_kr,
    formErrors: formErrors_kr,
  },
  vn: {
    apiErrors: apiErrors_vn,
    tooltips: tooltips_vn,
    app: app_vn,
    formErrors: formErrors_vn,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export const onChangeLanguage = (language: Language) => {
  i18n.changeLanguage(language).then(() => {
    console.log("Language changed to: ", language);
    switch (language) {
      case "cn":
        return moment.locale("zh-cn");
      case "ru":
        return moment.locale("ru");
      case "tr":
        return moment.locale("tr");
      case "kr":
        return moment.locale("kr");
      case "vn":
        return moment.locale("vi");
      default:
        return moment.locale("en-gb");
    }
  });
};
