import { AnimatePresence } from "framer-motion";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { arrowUpRight as Arrow } from "../../assets/styledSvg/arrowUpRight";
import { ReactComponent as Copy } from "../../assets/svg/copy.svg";
import { Button, ButtonThemeEnum } from "../../components/Buttons/styles";
import ClaimRewardsModal from "../../components/ClaimRewardsModal";
import BackgroundVideoFX from "../../components/shared/BackgroundVideoFX/BackgroundVideoFX";
import { Select } from "../../components/shared/Select";
import Transition from "../../components/shared/Transition";
import { LINKS, REWARD_LINKS } from "../../constants/links";
import { AccountStateEnum, AuthContext } from "../../contexts/AuthContext";
import { ConnectWalletContext } from "../../contexts/ConnectWalletContext";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useReferralStatistics } from "../../hooks/api/referrals/useReferralStatistics";
import { useToast } from "../../hooks/toast";
import { formatCompactCurrency } from "../../utils/math";
import { copyStringToClipboard } from "../../utils/strings";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import { ReferralTable } from "./ReferralTable";
import { RewardTable } from "./RewardTable";
import {
  HeaderContainer,
  HeaderTitle,
  ReferralLink,
  ReferralLinkWrapper,
  ReferralStat,
  ReferralStatsWrapper,
  TableContainer,
  VideoContainer,
} from "./style";

interface IReferralsTabProps {
  isMobileScreen?: boolean;
}

export function ReferralsTab({ isMobileScreen }: IReferralsTabProps) {
  const [selectedTable, setTable] = useState<"reward" | "referral">("reward");
  const [showRewardsModal, setShowRewardsModal] = useState<boolean>(false);
  const { data: accountData } = useGetAccount();
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const { accountApiKeyState, account } = useContext(AuthContext);
  const { data: referralStatsData } = useReferralStatistics();
  const { addToast } = useToast();
  const { t } = useTranslation("app", {
    keyPrefix: "pages.ReferralsTab.ReferralsTab",
  });

  const onCopy = useCallback(() => {
    if (accountData?.username) {
      copyStringToClipboard(`${LINKS.app}/r/${accountData.username}`);
      addToast({
        type: ToastEnum.SIMPLE,
        header: t("referral_link_copied"),
        status: ToastStatusEnum.SUCCESS,
      });
    }
  }, [accountData, addToast, t]);

  return (
    <>
      <ClaimRewardsModal
        show={showRewardsModal}
        onHide={() => setShowRewardsModal(false)}
        data={referralStatsData}
      />
      <VideoContainer isMobileScreen={isMobileScreen}>
        <BackgroundVideoFX />
      </VideoContainer>
      <HeaderContainer $isMobileScreen={isMobileScreen}>
        <HeaderTitle>{t("referrals")}</HeaderTitle>
        <span>
          {t("referrals_desc_1")}
          <br />
          <a
            href={REWARD_LINKS.referralClaims}
            target="_blank"
            rel="noreferrer"
          >
            {t("referrals_desc_2")}
            <Arrow />
          </a>
        </span>
        <ReferralStatsWrapper $isMobileScreen={isMobileScreen}>
          <ReferralStat>
            <span>{t("traders_referred")}</span>
            <p>{referralStatsData ? referralStatsData?.referred || 0 : "-"}</p>
          </ReferralStat>
          <ReferralStat>
            <span>{t("referees_volume")}</span>
            <p>
              {referralStatsData
                ? formatCompactCurrency(Number(referralStatsData?.volume || 0))
                : "-"}
            </p>
          </ReferralStat>
          <ReferralStat>
            <span>{t("unclaimed_rewards")}</span>
            <p>
              {referralStatsData
                ? formatCompactCurrency(
                    Number(referralStatsData?.total_rewards_unclaimed || 0)
                  )
                : "-"}
            </p>
          </ReferralStat>
          <ReferralStat>
            <span>{t("total_rewards_earned")}</span>
            <p>
              {referralStatsData
                ? formatCompactCurrency(
                    Number(referralStatsData?.total_rewards || 0)
                  )
                : "-"}
            </p>
          </ReferralStat>
        </ReferralStatsWrapper>
        <ReferralStat>
          <span>{t("referrer")}</span>
          <p>{accountData ? accountData.referrer : "-"}</p>
        </ReferralStat>
        <ReferralLinkWrapper $isMobileScreen={isMobileScreen}>
          <ReferralLink>
            {account && accountApiKeyState === AccountStateEnum.OK ? (
              <p>
                {LINKS.app}/r/<span>{accountData?.username}</span>
              </p>
            ) : (
              <p>-</p>
            )}
            <button type="button" onClick={onCopy}>
              <Copy />
            </button>
          </ReferralLink>

          {account && accountApiKeyState === AccountStateEnum.OK ? (
            <Button
              fullWidth
              buttonTheme={ButtonThemeEnum.PRIMARY}
              onClick={() => setShowRewardsModal(true)}
              disabled={
                !referralStatsData ||
                Number(referralStatsData?.total_rewards_unclaimed) === 0
              }
            >
              {t("claim_rewards")}
            </Button>
          ) : (
            <Button
              buttonTheme={ButtonThemeEnum.HIGHLIGHT}
              onClick={() => setShowConnectModal(true)}
            >
              {!account ? t("connect_wallet") : t("complete_sign_in")}
            </Button>
          )}
        </ReferralLinkWrapper>
      </HeaderContainer>
      <TableContainer>
        <Select
          isRound
          options={[
            {
              label: t("rewards_history"),
              isActive: selectedTable === "reward",
              onClick: () => setTable("reward"),
            },
            {
              label: t("referral_history"),
              isActive: selectedTable === "referral",
              onClick: () => setTable("referral"),
            },
          ]}
        />
        <AnimatePresence key={selectedTable}>
          <Transition>
            {selectedTable === "reward" ? (
              <RewardTable isMobileScreen={isMobileScreen} />
            ) : (
              <ReferralTable isMobileScreen={isMobileScreen} />
            )}
          </Transition>
        </AnimatePresence>
      </TableContainer>
    </>
  );
}
