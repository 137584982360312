import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Phone } from "../../../assets/svg/smartphone.svg";
import { SPACING } from "../../../constants/design/spacing";
import { LINKS } from "../../../constants/links";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import { useRegister } from "../../../hooks/api/register/useRegister";
import { usePWAFlow } from "../../../hooks/usePWAFlow";
import useWallet from "../../../hooks/wallet/useWallet";
import { createSigningKey } from "../../../utils/signing";
import { supportedSigningChainIds } from "../../../utils/wallet/connectors";
import { BaseModal } from "../../BaseModal";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import PageIndicator from "../../PageIndicator";
import Transition from "../Transition";
import GenerateQR from "./GenerateQR";
import { IContentTitle } from "./model";
import {
  Content,
  ErrorText,
  StepConnector,
  StepContent,
  StepNumber,
  Title,
} from "./style";
import { Spinner } from "../Spinner";

interface IDesktopPWAModalProps {
  show?: boolean;
  onHide?: () => void;
}

enum DesktopPWAPageEnum {
  MAIN = "MAIN",
  ENABLE_PWA_TRADING = "ENABLE_PWA_TRADING",
  QR_CODE = "QR_CODE",
}

function DesktopPWAModal({ onHide, show }: IDesktopPWAModalProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "Header.PWAFlowModal.DesktopPWAModal",
  });
  // const { accountKeyState, apiKey, apiSecret, signingKey, expiry } = useContext(AuthContext);

  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const { isWrongNetwork, setChainToSwitch, account, provider } = useWallet();
  const { postNewSigningKey } = useRegister();

  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState<string>();
  const [page, setPage] = useState(DesktopPWAPageEnum.ENABLE_PWA_TRADING);
  const [encryptedState, setEncryptedState] = useState<{
    url: string;
    password: string;
  }>();

  const { generateEncryptedURL } = usePWAFlow();

  // Hide
  useEffect(() => {
    // Reset when hide
    if (!show) {
      setPage(DesktopPWAPageEnum.MAIN);
      setEncryptedState(undefined);
    }
  }, [show]);

  const onSignMessage = useCallback(async () => {
    try {
      setPageError(undefined);
      setLoading(true);

      if (isWrongNetwork) {
        await setChainToSwitch(supportedSigningChainIds[0]);
        return;
      }

      if (account && provider) {
        const { chainId } = await provider.getNetwork();

        // Create a signing key with no expiry
        const signingKeyData = await createSigningKey({
          provider,
          account,
          chainId,
        });

        // Register it with API to get authInfo
        const { authInfo, error } = await postNewSigningKey(
          account,
          signingKeyData.registerMessage,
          signingKeyData.accountSignature,
          signingKeyData.signingKeySignature,
          signingKeyData.privateKey
        );
        if (authInfo) {
          const { url, password } = generateEncryptedURL(authInfo);
          setEncryptedState({
            url,
            password,
          });
          setPage(DesktopPWAPageEnum.QR_CODE);
        } else if (error) {
          setPageError(error);
        }
      }
    } catch (error) {
      setPageError(t("generic_error"));
    } finally {
      setLoading(false);
    }
  }, [
    account,
    generateEncryptedURL,
    isWrongNetwork,
    postNewSigningKey,
    provider,
    setChainToSwitch,
    t,
  ]);

  const onDownloadPWA = useCallback(() => {
    // If wallet isnt connect, just show connect wallet modal. Else show sign message modal
    if (!account) {
      setShowConnectModal(true);
    } else {
      setPage(DesktopPWAPageEnum.ENABLE_PWA_TRADING);
    }
  }, [account, setShowConnectModal]);

  const contentTitle: IContentTitle | null = useMemo(() => {
    switch (page) {
      case DesktopPWAPageEnum.MAIN:
        return {
          title: (
            <Title>
              <Phone />
              {t("title")}
            </Title>
          ),
          content: (
            <Content>
              {t("download_text_1")}{" "}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" target="_blank" rel="noreferrer">
                {t("download_text_2")}
              </a>{" "}
              {t("download_text_3")}
              <Button
                fullWidth
                buttonTheme={ButtonThemeEnum.PRIMARY}
                onClick={onDownloadPWA}
              >
                {t("download_pwa")}
              </Button>
              <Button
                fullWidth
                buttonTheme={ButtonThemeEnum.NEUTRAL3}
                onClick={onDownloadPWA}
              >
                {t("sync_with_pwa")}
              </Button>
            </Content>
          ),
        };
      case DesktopPWAPageEnum.ENABLE_PWA_TRADING:
        return {
          title: (
            <Title>
              <Phone />
              {t("enable_pwa_trading_title")}
            </Title>
          ),
          content: (
            <Content>
              {t("enable_pwa_text_1")} {/* TODO: - Add valid link */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href={LINKS.docs} target="_blank" rel="noreferrer">
                {t("enable_pwa_text_2")}
              </a>{" "}
              {t("enable_pwa_text_3")}
              {!!pageError && <ErrorText>{pageError}</ErrorText>}
              <Button
                fullWidth
                buttonTheme={ButtonThemeEnum.NEUTRAL3}
                onClick={onSignMessage}
              >
                {loading ? (
                  <Spinner />
                ) : isWrongNetwork ? (
                  t("switch_network")
                ) : (
                  t("sign_message")
                )}
              </Button>
            </Content>
          ),
        };
      case DesktopPWAPageEnum.QR_CODE:
        return {
          title: (
            <Title>
              <Phone />
              {t("download_pwa")}
            </Title>
          ),
          content: (
            <Content center>
              <GenerateQR qrCode={encryptedState?.url} />
              <StepNumber>1</StepNumber>
              <StepContent>{t("scan_qr_to_download")}</StepContent>
              <StepConnector />
              <StepNumber>2</StepNumber>
              <StepContent>
                {t("encryption_text")}{" "}
                <strong>{encryptedState?.password}</strong>
              </StepContent>
            </Content>
          ),
        };
      default:
        return null;
    }
  }, [
    encryptedState,
    isWrongNetwork,
    loading,
    onDownloadPWA,
    onSignMessage,
    page,
    pageError,
    t,
  ]);

  return (
    <BaseModal
      onHide={onHide}
      show={show}
      style={{
        width: 400,
      }}
      title={contentTitle?.title}
    >
      <Transition key={page} style={{ transitionDuration: "0.3s" }}>
        {contentTitle?.content}
      </Transition>
      {page !== DesktopPWAPageEnum.MAIN && (
        <PageIndicator
          style={{
            marginTop: SPACING.three,
          }}
          totalPages={2}
          currentPage={page === DesktopPWAPageEnum.ENABLE_PWA_TRADING ? 0 : 1}
        />
      )}
    </BaseModal>
  );
}

export default DesktopPWAModal;
