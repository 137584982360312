import { initializeConnector } from "@web3-react/core";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";

import { ChainIdEnum } from "../../../enums/chain";
import { isProduction } from "../../env";

// Sepolia testnet might not be supported in alot of walletconnect wallets
const chains = isProduction()
  ? [ChainIdEnum.ETH_MAINNET, ChainIdEnum.ARBITRUM, ChainIdEnum.OPTIMISM]
  : [
      ChainIdEnum.SEPOLIA_TESTNET,
      ChainIdEnum.LOCAL_TESTNET,
      ChainIdEnum.ARBITRUM_TESTNET,
      ChainIdEnum.OPTIMISM_TESTNET,
    ];

export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      defaultChainId: chains[0],
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID!,
        chains: [chains[0]],
        optionalChains: chains,
        showQrModal: true,
        optionalMethods: ["eth_signTypedData_v4"],
      },
    })
);
