import { AbsoluteContainer, AnnouncementsContainer } from "./style";
import Announcement from "./Announcement";
import {
  IAirtableValues,
  useAnnouncement,
} from "../../../hooks/api/announcement/useAnnouncement";

export function Announcements() {
  const announcements = useAnnouncement();
  return (
    <AbsoluteContainer>
      <AnnouncementsContainer>
        {announcements.map((announcement: IAirtableValues) => (
          <Announcement key={announcement.key} announcement={announcement} />
        ))}
      </AnnouncementsContainer>
    </AbsoluteContainer>
  );
}
